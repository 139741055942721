/* eslint-disable max-len */
import { Directive, ElementRef, HostListener, Renderer2, OnInit, Input, ComponentFactoryResolver, ViewContainerRef, OnDestroy } from '@angular/core';
import { EditionModeService } from '../../../services/edition-mode.service';
import { EditionAddComponent } from '../../../shared/components/mode-edition/edition-add/edition-add.component';
import { ScrollHorizontalService } from '../../../services/scroll-horizontal.service';

import { Subject } from 'rxjs';
import { TemasManagementService } from '../../../services/data/temas/temas-management.service';

@Directive({
    selector: '[edition]',
    exportAs: 'edition'
})
export class EditionDirective implements OnInit, OnDestroy {

    private static _elementRefSelected: ElementRef<any> = null;
    private static _positionBar = '';
    private static _options = '';
    private static _addBar = false;

    private destroy$: Subject<void> = new Subject<void>();

    @Input() positionBar = 'left';
    @Input() options = '';
    @Input() type = '';
    @Input() addBar = false;
    @Input() modulo = {} as any;
    @Input() parent = '';

    constructor(
        private _elementRef: ElementRef,
        private _renderer: Renderer2,
        private _editionModeService: EditionModeService,
        private temasManagementService: TemasManagementService,
        private viewContainerRef: ViewContainerRef,
        private resolver: ComponentFactoryResolver,
        private scrollHorizontalService: ScrollHorizontalService
    ) { }

    ngOnInit() {
        // Registra la instancia de la directiva en el servicio
        this._editionModeService.registerDirective(this);
    }

    ngOnDestroy() {
        // Desregistra la instancia de la directiva en el servicio
        this._editionModeService.unregisterDirective(this);
        this.destroy$.next();
        this.destroy$.complete();
    }



    @HostListener('document:click', ['$event.target'])
    public onOutsideClick(targetElement) {
        const _win = window as any;
        if (this._editionModeService.selected &&
            (targetElement && targetElement.className && targetElement.className.length > 0 && !targetElement.className.includes('ck-input-text') &&
                (!targetElement.className.includes('wrs') && !_win.WirisPlugin?.currentInstance?.core?.modalDialog?.properties?.open))) {
            const clickedInside = EditionDirective._elementRefSelected.nativeElement.contains(targetElement);
            if ((!clickedInside && (!this._editionModeService.dialogOpen))) {
                this.close();
            }
        }
    }

    close() {
        const _win = window as any;
        this._renderer.removeClass(EditionDirective._elementRefSelected.nativeElement, 'edition-selected');
        this._editionModeService.selected = false;
        this.leaveAnimationEditionBar();
        this.leaveAnimationEditionAdd();
        _win.WirisPlugin?.currentInstance?.core?.modalDialog?.close();
        this.scrollHorizontalService.scrollHorizontal = true;
    }

    public simulateOutsideClick() {
        this.close();
    }

    @HostListener('click', ['$event.target'])
    onClick(targetElement) {
        if (this._editionModeService.enable && !this._editionModeService.selected) {
            EditionDirective._elementRefSelected = this._elementRef;
            if (this.type === 'seccion1' && (this._editionModeService.type !== 'seccion1' || this._editionModeService.moduloSeleccionado?.id !== this.modulo.id)) {
                this._editionModeService.type = this.type;
                this._editionModeService.selected = false;
                this.leaveAnimationEditionBar();
                this.leaveAnimationEditionAdd();
            } else {

                this._renderer.addClass(EditionDirective._elementRefSelected.nativeElement, 'edition-selected');
                this._editionModeService.selected = true;
                this._editionModeService.position = this.positionBar;
                this._editionModeService.setMenu(this.options);
                this._editionModeService.type = this.type;
                this._editionModeService.parentInjector = this.viewContainerRef.parentInjector as any;
                this._editionModeService.moduloSeleccionado = this.modulo;
                this._editionModeService.parent = this.parent;
                this._editionModeService.orientation = this._elementRef.nativeElement.classList.contains('actividad') ? 'vertical' : 'horizontal';
                EditionDirective._positionBar = this.positionBar;
                EditionDirective._options = this.options;

                this.enterAnimationEditionBar();
                this.enterAnimationEditionAdd();
            }
        } else {
            const _win = window as any;
            if (this._editionModeService.moduloSeleccionado?.comunes?.tipoModulo?.valor !== 'texto') {
                if (EditionDirective._elementRefSelected?.nativeElement) {
                    this._renderer.removeClass(EditionDirective._elementRefSelected?.nativeElement, 'edition-selected');
                }
                this._editionModeService.selected = false;
                this.leaveAnimationEditionBar();
                this.leaveAnimationEditionAdd();
            }


        }
    }



    /**
     * Activa la animacion de entrada de la barra de edición del elemento seleccionado.
     */
    private enterAnimationEditionBar() {
        const addClassName = EditionDirective._positionBar === 'right' ? 'fadeInRight' : 'fadeInLeft';
        const editionBar = document.getElementById('edition-bar');
        this._renderer.setStyle(editionBar, 'top', `${this.getPosition()}px`);
        this._renderer.removeClass(editionBar, 'fadeOutRight');
        this._renderer.removeClass(editionBar, 'fadeOutLeft');
        setTimeout(() => this._renderer.addClass(editionBar, addClassName), 300);


    }

    /**
     * Activa la animacion de salida de la barra de edición del elemento seleccionado.
     */
    private leaveAnimationEditionBar() {
        const addClassName = EditionDirective._positionBar === 'right' ? 'fadeOutRight' : 'fadeOutLeft';
        const editionBar = document.getElementById('edition-bar');

        // this._renderer.setStyle(editionBar, 'top', `${0}px`);
        this._renderer.removeClass(editionBar, 'fadeInRight');
        this._renderer.removeClass(editionBar, 'fadeInLeft');
        this._renderer.addClass(editionBar, addClassName);
        if (this._editionModeService.type === 'texto' && this._editionModeService.action === 'edit') {
            this._editionModeService.parentInjector._lView[8].isDisabled = false;
        }
        // setTimeout(() => this._renderer.addClass(editionBar, addClassName), 300);
    }

    /**
     * Activa la animacion de entrada del EditionAdd si el elemento seleccionado tiene AddBar.
     */
    private enterAnimationEditionAdd() {
        if (this.addBar) {
            EditionDirective._addBar = this.addBar;
            const factory = this.resolver.resolveComponentFactory(EditionAddComponent);
            const editionAddBefore = this.viewContainerRef.createComponent(factory);
            const editionAddAfter = this.viewContainerRef.createComponent(factory);
            if (this._editionModeService.orientation === 'horizontal') {
                editionAddBefore.instance.posicion = 'up';
                editionAddAfter.instance.posicion = 'down';
            }
            else {
                editionAddBefore.instance.posicion = 'before';
                editionAddAfter.instance.posicion = 'after';
            }

            if (this.type.includes('seccion1')) {

                const accordeon = document.querySelector('.tema-list');

                editionAddBefore.instance.setOptions('seccion1');
                this.setOptions(this.type, editionAddAfter.instance);

                this.enableSectionAdd(editionAddBefore, accordeon, this.getPosition('default-drawer') - 39 - 280);
                this.enableSectionAdd(editionAddAfter, accordeon, this.getPosition('default-drawer') + 32 - 280);
            } else {

                this.setOptions(this.type, editionAddBefore.instance);
                this._elementRef.nativeElement
                    .insertBefore(editionAddBefore.location.nativeElement, this._elementRef.nativeElement.firstChild);
                this.setOptions(this.type, editionAddAfter.instance);
                this._elementRef.nativeElement.appendChild(editionAddAfter.location.nativeElement);
            }
            this.temasManagementService.saveScrollPosition();
        }
    }

    /**
     * Activa la animacion de salida del EditionAdd si el elemento seleccionado tiene AddBar.
     */
    private leaveAnimationEditionAdd() {
        if (EditionDirective._addBar) {
            EditionDirective._addBar = false;
            this.viewContainerRef.clear();
            document.querySelectorAll('aula-planeta-edition-add').forEach(e => e.remove());
        }
    }

    /**
     * Obtiene la posicion del elemento seleccionado en funcion del parameto.
     * Si no hay parametro, obtendrá la posicion en relación al body.
     *
     * @param stop: String Clase del elemento hasta el que se quiere obtener la posicion en relacion al elemento seleccionado.
     * @returns yPosition: Number Posicion del elemento seleccionado en relacion al parametro pasado o al body.
     */
    getPosition(stop: any = null) {
        let yPosition = 0;
        let element = EditionDirective._elementRefSelected.nativeElement;

        while (element) {
            yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
            element = element.offsetParent;
            if (stop && element.classList.contains(stop)) {
                break;
            }
        }

        return yPosition;
    }

    getXPosition(stop: any = null) {

        const element = EditionDirective._elementRefSelected.nativeElement;
        const xPosition = element.getBoundingClientRect().left + element.offsetWidth;
        return xPosition;
    }



    /**
     * Añade el component Edition Bar en el next o en el before del elemento seleccionado.
     * Esta funcion solo se usa con las secciones de primer nivel, ya que el EditionBar requiere en este punto que sea absoluto.
     *
     * @param element: ComponentRef Referencia del componente Edition Bar
     * @param parentElement: Element Elemento contenedor de donde se quiere insertar el Edition Bar y que dentro esta el elemento seleccionado
     * @param position: Number Posicion en pixeles del elemento seleccionado en pantalla.
     */
    enableSectionAdd(element, parentElement, position) {
        this._renderer.addClass(element.location.nativeElement, 'position-absolute');
        this._renderer.addClass(element.location.nativeElement, 'w-100');
        this._renderer.setStyle(element.location.nativeElement, 'z-index', '11');

        parentElement.parentNode.insertBefore(element.location.nativeElement, parentElement.nextSibling);

        this._renderer.setStyle(element.location.nativeElement, 'top', `${position}px`);
    }

    /**
     * Setea en las barras de añadir modulos las distintas opciones segun el tipo de elemento selecionado.
     * @param typeElement: String Tipo de elemento seleccionado
     * @param addBarInstance: ComponentRef Referencia del componente EditionBar, puede ser el before o el after del elemento seleccionado.
     */
    setOptions(typeElement, addBarInstance) {
        switch (typeElement) {
            case 'seccion1':
                addBarInstance.setOptions('seccion1, seccion2, divider, texto, imagen, recurso, destacado, documento, audio, tabla, video, enlace');
                break;
            case 'seccion1-recurso':
                addBarInstance.setOptions('seccion1, seccion2, divider, recurso');
                break;
            case 'seccion2':
                addBarInstance.setOptions('seccion2, seccion3, divider, texto, imagen, recurso, destacado, documento, audio, tabla, video, enlace');
                break;
            case 'seccion3':
                addBarInstance.setOptions('seccion3, divider, texto, imagen, recurso, destacado, documento, audio, tabla, video, enlace');
                break;
            case 'seccion4':
                addBarInstance.setOptions('divider, texto, imagen, recurso, destacado, documento, audio, tabla, video, enlace');
                break;
            case 'recurso-col':
                addBarInstance.setOptions('recurso');
                break;
            case 'recurso':
                addBarInstance.setOptions('seccion2, divider, texto, imagen, recurso, destacado, documento, audio, tabla, video, enlace');
                break;
            default:
                addBarInstance.setOptions('seccion2, divider, texto, imagen, recurso, destacado, documento, audio, tabla, video, enlace');
        }
    }
}
