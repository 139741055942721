import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MainPlataformaComponent } from './main-plataforma/main-plataforma.component';
import { MainMoodleComponent } from './main-moodle/main-moodle.component';
import { routeAnimations } from '../../core/core.module';
import { AuthManagementService } from '../../core/auth/auth-management.service';

@Component({
  selector: 'aula-planeta-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [routeAnimations]
})
export class MainComponent implements OnInit {
  isMoodle = false;

  mainDinamico: any;

  constructor(private authManagementService: AuthManagementService) {
    this.isMoodle = this.authManagementService.userCanPermission(['AccesoLtiTareas']);
  }

  ngOnInit() {
    if (this.isMoodle) {
      this.mainDinamico = MainMoodleComponent;
    } else {
      this.mainDinamico = MainPlataformaComponent;
    }

  }
}
