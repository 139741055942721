
import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'aula-planeta-mis-clases',
  templateUrl: './mis-clases.component.html',
  styleUrls: ['./mis-clases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MisClasesComponent implements OnInit {

  displayedColumns: string[] = [];
  enableSaveButton = false;

  constructor(
    public dialogRef: MatDialogRef<MisClasesComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  ngOnInit() {
    this.getDisplayedColumns();
  }

  getDisplayedColumns() {
    this.displayedColumns = [];
    this.displayedColumns.push('curso');
    this.data.grupos.forEach(grupo => this.displayedColumns.push(grupo.id));
    return this.displayedColumns;
  }


  selectClase(cursoId, grupoId) {
    this.enableSaveButton = true;
    const existe = this.data.clases.some(clase => clase.cursoId === cursoId && clase.grupoId === grupoId);
    if (existe) {
      this.data.clases = this.data.clases.filter(clase => !(clase.cursoId === cursoId && clase.grupoId === grupoId));
    } else {
      this.data.clases.push({ cursoId, grupoId });
    }
  }

  isEstaUnaDeMisClases(cursoId, grupoId) {
    if (this.data.clases && this.data.clases.length > 0) {
      return this.data.clases.some(clase => clase.cursoId === cursoId && clase.grupoId === grupoId);
    }
    return false;
  }

  close(result) {
    if (result) {
      this.dialogRef.close(this.data.clases);
    } else {
      this.dialogRef.close();
    }
  }
}
