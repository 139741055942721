import { Injectable, HostListener } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { SmartlinkManagementService } from '../data/smartlink/smartlink-management-service.service';
import { AuthManagementService, LocalStorageService } from '../../core/core.module';
import { TemasManagementService } from '../data/temas/temas-management.service';

@Injectable({
  providedIn: 'root'
})
export class Smartlink {
  private destroy$: Subject<void> = new Subject<void>();

  public smartlink = false;

  constructor(
    private router: Router,
    private smartlinkManagementService: SmartlinkManagementService,
    private authManagementService: AuthManagementService,
    private temasManagementService: TemasManagementService,
    private localStorageService: LocalStorageService
  ) {
    // Recuperar el valor de sessionStorage cuando se inicializa el servicio
    const sessionSmartlink = sessionStorage.getItem('smartlink');
    if (sessionSmartlink) {
      this.smartlink = JSON.parse(sessionSmartlink);
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.authManagementService.redirectUrl = state.url;
    const tmp$: Subject<void> = new Subject<void>();
    const [id, page] = [route.paramMap.get('id'), route.paramMap.get('page')];
    const token = await this.getToken();
    this.smartlinkManagementService.getSmartlinkData(page, id).pipe(
      take(1),
      takeUntil(tmp$))
      .subscribe(
        res => {
          tmp$.next();
          tmp$.complete();
          const url = this.formarUrl(page, res.materiaId, res.temaId, res.recursoId, res.seccionId, res.indiceSeccion);
          localStorage.removeItem('redirectUrl');
          if (this.authManagementService.currentUser.moodle) {
            // url = `${url}?moodle=${token}`;
          }
          this.router.navigateByUrl(url, { replaceUrl: true });
          // this.temasManagementService.fullscreen = true;
          this.smartlinkManagementService.smartlink = true;
          // Guardar el valor en sessionStorage
          sessionStorage.setItem('smartlink', JSON.stringify(this.smartlinkManagementService.smartlink));
          sessionStorage.setItem('redirectUrl', url);
        },
        err => {
          localStorage.removeItem('redirectUrl');
        }
      );
    return false;
  }

  private formarUrl(page, idMateria, idTema, idRecurso, idSeccion, indiceSeccion) {
    switch (page) {
      case 'materia':
        return `/inicio/mis-materias/materia/${idMateria}`;
      case 'tema':
        return `/inicio/mis-materias/materia/${idMateria}/tema/${idTema}`;
      case 'seccion':
        return `/inicio/mis-materias/materia/${idMateria}/tema/${idTema}#${idSeccion}---${indiceSeccion}`;
      case 'recurso':
        return `/inicio/mis-materias/materia/${idMateria}/tema/${idTema}/recurso/${idRecurso}`;
      default:
        break;
    }
  }

  private async getToken() {
    return await this.localStorageService.getItem('JWT').pipe(first()).toPromise();
  }

  // Eliminar el valor de sessionStorage cuando se cierra la ventana
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    sessionStorage.removeItem('smartlink');
    sessionStorage.removeItem('redirectUrl');
  }
}
