<div id="dialog-configurar-aulaplaneta" role="form" class="standard">

    <h1 class="custom-text heading-6 font-weight-bold" mat-dialog-title>{{'dialogs.configurar-aulaplaneta.title' | translate}}</h1>
    <div mat-dialog-content class="pe-2">
        <div class="pe-2">
            <mat-tab-group mat-align-tabs="start">
                <mat-tab *ngIf="(materiaManagementService.asignaturasLOMLOE$ | async) && materiaManagementService.asignaturasLOMLOE.length > 0" label="{{ 'dialogs.configurar-aulaplaneta.asignaturasLOMLOE' | translate }}">
                    <table #table mat-table class="w-100 default-table" (matSortChange)="sortData($event)" matSort [dataSource]="materiaManagementService.asignaturasLOMLOE$ | async">

                        <ng-container matColumnDef="asignaturas">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-text font-weight-bold ps-0 w-30"></th>
                            <td mat-cell class="font-weight-bold" *matCellDef="let element; index as numrow" [ngClass]="colorHelper.getColor(element.materias[0]?.color)" id="Row_LOMLOE_{{numrow}}">
                                <span class="custom-text">{{element.materias[0]?.nombre}}</span>
                            </td>
                        </ng-container>

                        <ng-container *ngFor="let curso of miCentroManagementService.cursos$ | async; index as numrow" [matColumnDef]="curso.id+''">
                            <th mat-header-cell *matHeaderCellDef class="custom-text font-weight-bold w-7" id="Col_LOMLOE_{{curso.ordinal}}_{{curso.etapa.acronimo}}"> {{ curso.ordinal }} {{curso.etapa.acronimo}}</th>
                            <td mat-cell *matCellDef="let asignatura">
                                <mat-checkbox aria-labelledby="Row_LOMLOE_{{numrow}} Col_LOMLOE_{{curso.ordinal}}_{{curso.etapa.acronimo}}" *ngIf="isEstaUnaMateriaDisponible(asignatura.materias, curso.id)" (click)="$event.stopPropagation()" (change)="$event ? selectMateria(asignatura.materias, curso.id) : null "
                                    [checked]="isEstaUnaDeMisMaterias(asignatura.materias, curso.id)"></mat-checkbox>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
                    </table>
                </mat-tab>
                <mat-tab *ngIf="(materiaManagementService.asignaturasLOMCE$ | async) && materiaManagementService.asignaturasLOMCE.length > 0" label="{{ 'dialogs.configurar-aulaplaneta.asignaturasLOMCE' | translate }}">
                    <table #table mat-table class="w-100 default-table" (matSortChange)="sortData($event)" matSort [dataSource]="materiaManagementService.asignaturasLOMCE$ | async">

                        <ng-container matColumnDef="asignaturas">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-text font-weight-bold ps-0 w-30"></th>
                            <td mat-cell class="font-weight-bold" *matCellDef="let element; index as numrow" [ngClass]="colorHelper.getColor(element.materias[0]?.color)" id="Row_LOMCE_{{numrow}}">
                                <span class="custom-text">{{element.materias[0]?.nombre}}</span>
                            </td>
                        </ng-container>

                        <ng-container *ngFor="let curso of miCentroManagementService.cursos$ | async; index as numrow" [matColumnDef]="curso.id+''">
                            <th mat-header-cell *matHeaderCellDef class="custom-text font-weight-bold w-7" id="Col_LOMCE_{{curso.ordinal}}_{{curso.etapa.acronimo}}"> {{ curso.ordinal }} {{curso.etapa.acronimo}}</th>
                            <td mat-cell *matCellDef="let asignatura">
                                <mat-checkbox aria-labelledby="Row_LOMCE_{{numrow}} Col_LOMCE_{{curso.ordinal}}_{{curso.etapa.acronimo}}" *ngIf="isEstaUnaMateriaDisponible(asignatura.materias, curso.id)" (click)="$event.stopPropagation()" (change)="$event ? selectMateria(asignatura.materias, curso.id) : null "
                                    [checked]="isEstaUnaDeMisMaterias(asignatura.materias, curso.id)"></mat-checkbox>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
                    </table>
                </mat-tab>
                <mat-tab *ngIf="(materiaManagementService.migradas$ | async) && materiaManagementService.migradas.length > 0" label="{{ 'dialogs.configurar-aulaplaneta.asignaturasMigradas' | translate }}">
                    <table #table mat-table class="w-100 default-table" (matSortChange)="sortData($event)" matSort [dataSource]="materiaManagementService.migradas$ | async">

                        <ng-container matColumnDef="asignaturas">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-text font-weight-bold ps-0 w-30"></th>
                            <td mat-cell class="font-weight-bold" *matCellDef="let element; index as numrow" [ngClass]="colorHelper.getColor(element.materias[0]?.color)" id="Row_MIGRA_{{numrow}}">
                                <span class="custom-text">{{element.materias[0]?.nombre}}</span>
                            </td>
                        </ng-container>

                        <ng-container *ngFor="let curso of miCentroManagementService.cursos$ | async; index as numrow" [matColumnDef]="curso.id+''">
                            <th mat-header-cell *matHeaderCellDef class="custom-text font-weight-bold w-7" id="Col_MIGRA_{{curso.ordinal}}_{{curso.etapa.acronimo}}"> {{ curso.ordinal }} {{curso.etapa.acronimo}}</th>
                            <td mat-cell *matCellDef="let asignatura">
                                <mat-checkbox aria-labelledby="Row_MIGRA_{{numrow}} Col_MIGRA_{{curso.ordinal}}_{{curso.etapa.acronimo}}" *ngIf="isEstaUnaMateriaDisponible(asignatura.materias, curso.id)" (click)="$event.stopPropagation()" (change)="$event ? selectMateria(asignatura.materias, curso.id) : null "
                                    [checked]="isEstaUnaDeMisMaterias(asignatura.materias, curso.id)"></mat-checkbox>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
                    </table>
                </mat-tab>
                <mat-tab *ngIf="(materiaManagementService.otrasMateriasColegio$ | async) && materiaManagementService.otrasMateriasColegio.length > 0" label="{{ 'dialogs.configurar-aulaplaneta.asignaturasOtrasCentro' | translate }}">
                    <table #table mat-table class="w-100 default-table" (matSortChange)="sortData($event)" matSort [dataSource]="materiaManagementService.otrasMateriasColegio$ | async">

                        <ng-container matColumnDef="asignaturas">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-text font-weight-bold ps-0 w-30"></th>
                            <td mat-cell class="font-weight-bold" *matCellDef="let element; index as numrow" [ngClass]="colorHelper.getColor(element.materias[0]?.color)" id="Row_CENTRO_{{numrow}}">
                                <span class="custom-text">{{element.nombre}}</span>
                            </td>
                        </ng-container>

                        <ng-container *ngFor="let curso of miCentroManagementService.cursos$ | async; index as numrow" [matColumnDef]="curso.id+''">
                            <th mat-header-cell *matHeaderCellDef class="custom-text font-weight-bold w-7" id="Col_CENTRO_{{curso.ordinal}}_{{curso.etapa.acronimo}}"> {{ curso.ordinal }} {{curso.etapa.acronimo}}</th>
                            <td mat-cell *matCellDef="let asignatura">
                                <mat-checkbox aria-labelledby="Row_CENTRO_{{numrow}} Col_CENTRO_{{curso.ordinal}}_{{curso.etapa.acronimo}}" *ngIf="isEstaUnaMateriaDisponible(asignatura.materias, curso.id)" (click)="$event.stopPropagation()" (change)="$event ? selectMateria(asignatura.materias, curso.id) : null "
                                    [checked]="isEstaUnaDeMisMaterias(asignatura.materias, curso.id)"></mat-checkbox>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
                    </table>
                </mat-tab>
            </mat-tab-group>
        </div>

    </div>

    <div mat-dialog-actions class="justify-content-start align-items-end mt-4">
        <button role="button" class="default-button default-button__primary me-3" mat-dialog-close (click)="close(false)">{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__secondary" (click)="close(true)" type="submit" cdkFocusRegionEnd>{{ 'generico.anadir' | translate }}</button>
    </div>

</div>