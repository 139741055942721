/* eslint-disable @typescript-eslint/naming-convention */
import { IndiceSeccion } from './indice-seccion.model';
import { Expandable } from '../expandable.model';
import { Modulo } from './modulo.model';
import { Unidad } from './unidad.model';

export class Tema extends Expandable {

  id: string;
  imagen: string;
  autor: any;
  tituloHtml: string;
  tituloHtmml?: string;
  descripcion_html: string;
  orden?: number;
  unidad: Unidad;
  indiceSecciones: IndiceSeccion[];
  organizacion?: any;
  modulosOrganizacion?: Modulo[];
  compartir?: string;
  compartirCon: any[];
  visible?: string;
  enEdicion?: boolean;
  tituloPlano?: string;
  idParent?: string;
  alumnosIds?: any[];
  isPlaneta?: boolean;
  isPersonalized?: boolean;
  isSharedWithOthers?: boolean;
  isSharedWithMeToRead?: boolean;
  isSharedWithMeToWrite?: boolean;
  isHidden?: boolean;

  constructor(tema) {
    super();
    Object.assign(this, tema);
  }

}





