import { Directive, ElementRef, HostListener, OnInit, Input, Renderer2, OnChanges, SimpleChanges } from '@angular/core';
import { EnvService } from '../../../services/env.service';

const URL_ERROR_IMG = '/assets/images/no-image.png';
const URL_ERROR_IMG_BANCO = 'assets/images/banco-contenido.png';

@Directive({
  selector: 'img[sanitize-img]'
})
export class SanitizeImgDirective implements OnInit, OnChanges {
  @Input() type = '';
  @Input() imgsrc = '';
  @Input() absolute = false;
  @Input() banco = false;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private envService: EnvService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateImageSrc();
  }

  ngOnInit() {
    this.updateImageSrc();
  }

  @HostListener('error')
  private onError() {
    this.swichType();
  }

  private updateImageSrc() {
    const nativeElement = this._elementRef.nativeElement;
    if (nativeElement.src) {
      if (!this.absolute) {
        const url = new URL(nativeElement.src);
        if (this.banco) {
          nativeElement.src = url.pathname === '/' ? '' : `${this.envService.bancoUri}/multimedia/s${url.pathname}`;
        } else {
          nativeElement.src = url.pathname === '/' ? '' : `${this.envService.rootContent}${url.pathname}`;
        }
      }
    } else {
      this.swichType();
    }
   
  }

 swichType() {
    switch (this.type) {
      case 'avatar': {
        this.showAvatar();
        break;
      }
      case 'default': {
        this._elementRef.nativeElement.src = URL_ERROR_IMG;
        break;
      }
      case 'banco': {
        this._elementRef.nativeElement.src = URL_ERROR_IMG_BANCO;
        break;
      }
      default: {
        this._elementRef.nativeElement.hidden = true;
        break;
      }
    }
  }

  showAvatar() {
    this._elementRef.nativeElement.hidden = true;
    const avatar = this._elementRef.nativeElement.nextSibling;
    this._renderer.removeClass(avatar, 'd-none');
  }
}
