import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Materia } from '../../../models/materia.model';
import { ColorHelper } from '../../../helper/color.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { MateriaManagementService } from '../../../../services/data/materia/materia-management.service';
import { MiCentroManagementService } from '../../../../features/mi-centro/services/mi-centro-management.service';
import { Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnvService } from '../../../../services/env.service';

@Component({
  selector: 'aula-planeta-configurar-aulaplaneta',
  templateUrl: './configurar-aulaplaneta.component.html',
  styleUrls: ['./configurar-aulaplaneta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurarAulaplanetaComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  displayedColumns: string[] = [];
  colorList: string[] = [];
  misMaterias: any[];

  constructor(
    public colorHelper: ColorHelper,
    public dialogRef: MatDialogRef<ConfigurarAulaplanetaComponent>,
    public materiaManagementService: MateriaManagementService,
    public miCentroManagementService: MiCentroManagementService,
    public envService: EnvService,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
  }

  ngOnInit() {

    this.materiaManagementService.misMaterias$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(materias => {
        this.misMaterias = materias;
      });

    this.miCentroManagementService.cursos$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(cursos => {
        if (cursos) {
          cursos.sort(function (a, b) {
            if (a.etapa.nombre === b.etapa.nombre) {
              return a.ordinal - b.ordinal;
            }
            return a.etapa.nombre > b.etapa.nombre ? 1 : -1;
          });
          this.getDisplayedColumns(cursos);
        }
      });

    const info = {
      event: 'popup',
      popup: {
        popupId: 'dialog-configurar-aulaplaneta',
        popupName: 'popup_configurar_aulaplaneta'
      }
    };
    this.gtagService.event(info);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  getDisplayedColumns(cursos) {
    this.displayedColumns = [];
    this.displayedColumns.push('asignaturas');
    cursos.forEach(curso => this.displayedColumns.push(`${curso.id}`));
    return this.displayedColumns;
  }


  selectMateria(materias, grupoId) {
    // EJEMPLO: Puede haber mas de una materia de lengua extranjera
    // en un curso por lo que al hacer un click habrá de enviar los id de todas las materias que cuelquen
    const materiasDeLaAsignaturaSeleccionada = materias.filter(materia => (materia.curso.id === grupoId));

    let existe = false;
    this.misMaterias.forEach(miMateria => {
      materiasDeLaAsignaturaSeleccionada.forEach(materiaDeLaAsignaturaSeleccionada => {
        if (miMateria.id + '' === materiaDeLaAsignaturaSeleccionada.id + '') {
          existe = true;
        }
      });
    });
    if (existe) {
      materiasDeLaAsignaturaSeleccionada.forEach(materia => {
        this.misMaterias = this.misMaterias.filter(miMateria => (miMateria.id + '' !== materia.id + ''));
      });

    } else {
      this.misMaterias = this.misMaterias.concat(materiasDeLaAsignaturaSeleccionada);
    }
  }

  isEstaUnaDeMisMaterias(materias: Materia[], cursoId: number) {
    const _materias = materias.filter(materia => materia.curso.id === cursoId);
    let encontrada = false;
    _materias.forEach(_materia => {
      encontrada = this.misMaterias.some(miMateria => (miMateria.id + '' === _materia.id + ''));
      if (encontrada) {
        return;
      }
    });
    return encontrada;
  }

  isEstaUnaMateriaDisponible(materias: Materia[], cursoId: number) {
    return materias.some(materia => materia.curso.id === cursoId);
  }

  close(result) {
    if (result) {
      this.dialogRef.close(this.misMaterias);
    } else {
      this.dialogRef.close();
    }
  }

  sortData(sort: Sort) {
    const data = this.materiaManagementService.asignaturas.slice();
    if (!sort.active || sort.direction === '') {
      sort.active = '';
      sort.direction = 'asc';
    }

    const materiasOrdenadas = data.sort(function (a, b) {
      const isAsc = sort.direction === 'asc';

      const nameA = a.materias[0].nombre.toUpperCase();
      const nameB = b.materias[0].nombre.toUpperCase();
      return (nameA < nameB ? -1 : 1) * (isAsc ? 1 : -1);
    });

    this.updateTableValues(materiasOrdenadas);
  }

  // ************** Refrescar datos tabla manualemente ************
  private updateTableValues(materias: any[]) {
    this.materiaManagementService.asignaturas = [...materias];
  }
}
