/* eslint-disable max-len */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Observable, forkJoin } from 'rxjs';
import { Materia } from '../../../shared/models/materia.model';
import { MateriaDataService } from './materia-data.service';
import { take, takeUntil, filter, switchMap, tap } from 'rxjs/operators';
import { ProyectoManagementService } from '../proyecto/proyecto-management.service';
import { AuthManagementService } from '../../../core/auth/auth-management.service';
import { LocalCacheService } from '../../../core/load-cache/load-cache.service';
import { NotificationService } from '../../../core/notifications/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Etapa } from '../../../shared/models/etapa.model';
import { EnvService } from '../../env.service';
@Injectable({
  providedIn: 'root'
})
export class MateriaManagementService implements OnDestroy {

  otrasMaterias$: Observable<Materia[]>;
  materiasMaestras$: Observable<Materia[]>;
  misMaterias$: Observable<Materia[]>;
  misLibros$: Observable<Materia[]>;
  materiasColegio$: Observable<Materia[]>;
  asignaturas$: Observable<Materia[]>;
  asignaturasLOMCE$: Observable<Materia[]>;
  asignaturasLOMLOE$: Observable<Materia[]>;
  migradas$: Observable<Materia[]>;
  otrasMateriasColegio$: Observable<Materia[]>;
  materia$: Observable<Materia>;
  istemasOriginales$: Observable<boolean>;

  private _otrasMaterias: BehaviorSubject<Materia[]> = new BehaviorSubject<Materia[]>([]);
  private _materiasMaestras: BehaviorSubject<Materia[]> = new BehaviorSubject<Materia[]>([]);
  private _misMaterias: BehaviorSubject<Materia[]> = new BehaviorSubject<Materia[]>(null);
  private _misLibros: BehaviorSubject<Materia[]> = new BehaviorSubject<Materia[]>(null);
  private _materiasColegio: BehaviorSubject<Materia[]> = new BehaviorSubject<Materia[]>(null);
  private _asignaturas: BehaviorSubject<Materia[]> = new BehaviorSubject<Materia[]>([]);
  private _asignaturasLOMCE: BehaviorSubject<Materia[]> = new BehaviorSubject<Materia[]>([]);
  private _asignaturasLOMLOE: BehaviorSubject<Materia[]> = new BehaviorSubject<Materia[]>([]);
  private _migradas: BehaviorSubject<Materia[]> = new BehaviorSubject<Materia[]>([]);
  private _otrasMateriasColegio: BehaviorSubject<Materia[]> = new BehaviorSubject<Materia[]>([]);
  private _materia: BehaviorSubject<Materia> = new BehaviorSubject<Materia>(null);
  private _istemasOriginales: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private authManagementService: AuthManagementService,
    private materiaDataService: MateriaDataService,
    private proyectoDataService: ProyectoManagementService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private localCacheService: LocalCacheService,
    private envService: EnvService
  ) {
    this.otrasMaterias$ = this._otrasMaterias.asObservable();
    this.materiasMaestras$ = this._materiasMaestras.asObservable();
    this.misMaterias$ = this._misMaterias.asObservable();
    this.misLibros$ = this._misLibros.asObservable();
    this.materiasColegio$ = this._materiasColegio.asObservable();
    this.asignaturas$ = this._asignaturas.asObservable();
    this.asignaturasLOMCE$ = this._asignaturasLOMCE.asObservable();
    this.asignaturasLOMLOE$ = this._asignaturasLOMLOE.asObservable();
    this.migradas$ = this._migradas.asObservable();
    this.otrasMateriasColegio$ = this._otrasMateriasColegio.asObservable();
    this.materia$ = this._materia.asObservable();
    this.istemasOriginales$ = this._istemasOriginales.asObservable();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  set materia(materia) {
    this.materiaNext(materia);
  }

  get materia() {
    return this._materia.getValue();
  }

  get misMaterias() {
    return this._misMaterias.getValue();
  }

  get misLibros() {
    return this._misLibros.getValue();
  }

  get materiasColegio() {
    return this._materiasColegio.getValue();
  }

  get asignaturas() {
    return this._asignaturas.getValue();
  }

  set asignaturas(asignaturas: any[]) {
    this._asignaturas.next(asignaturas);
  }

  get asignaturasLOMCE() {
    return this._asignaturasLOMCE.getValue();
  }

  set asignaturasLOMCE(asignaturasLOMCE: any[]) {
    this._asignaturasLOMCE.next(asignaturasLOMCE);
  }

  get asignaturasLOMLOE() {
    return this._asignaturasLOMLOE.getValue();
  }

  set asignaturasLOMLOE(asignaturasLOMLOE: any[]) {
    this._asignaturasLOMLOE.next(asignaturasLOMLOE);
  }

  get migradas() {
    return this._migradas.getValue();
  }

  set migradas(migradas: any[]) {
    this._migradas.next(migradas);
  }

  get otrasMateriasColegio() {
    return this._otrasMateriasColegio.getValue();
  }

  set otrasMateriasColegio(otrasMateriasColegio: any[]) {
    this._otrasMateriasColegio.next(otrasMateriasColegio);
  }

  get otrasMaterias() {
    return this._otrasMaterias.getValue();
  }

  get materiasMaestras() {
    return this._materiasMaestras.getValue();
  }

  get temasOriginales() {
    return this._istemasOriginales.getValue();
  }

  set istemasOriginales(value: boolean) {
    this._istemasOriginales.next(value);
  }

  getMisMaterias() {
    this.materiaDataService.getMisMaterias().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          const materias = res.filter(materia => materia.materiaTipo === 'Materia');
          const materiasColegio = res.filter(materia => materia.materiaTipo === 'Colegio');
          const proyectos = res.filter(materia => materia.materiaTipo === 'Proyecto');
          this.misMateriasNext(materias);
          this.materiasColegioNext(materiasColegio);
          this.proyectoDataService.misProyectos = proyectos;
        }
      );
    if (this.authManagementService.currentUser?.usuarioRoles?.includes('Profesor')) {
      this.getMisLibros();
    }
  }

  getMisLibros() {
    const incluirIndiceTemasLibros = true;
    this.materiaDataService.getMisLibros(incluirIndiceTemasLibros).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          const libros = res.filter(libro => libro.materiaTipo === 'Materia');
          this.misLibrosNext(libros);
        }
      );
  }

  getAsignaturas() {
    this.materiaDataService.getAsignaturas().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {


          let migradas = [];

          // let _otrasMateriasColegio;
          // let otrasMateriasColegio = [];

          this.asignaturasLOMLOE = res.find
            (x => x.selectorAsignaturaTipo === 'LOMLOE' || x.selectorAsignaturaTipo === 'LGE')?.asignaturas;
          this.asignaturasLOMCE = res.find(x => x.selectorAsignaturaTipo === 'LOMCE')?.asignaturas;
          const _migradas = res.find(x => x.selectorAsignaturaTipo === 'Migrada')?.asignaturas;
          // _otrasMateriasColegio = res.find(x => x.selectorAsignaturaTipo === 'Centro')?.asignaturas;
          this.otrasMateriasColegio = res.find(x => x.selectorAsignaturaTipo === 'Centro')?.asignaturas;
          migradas = [];
          // otrasMateriasColegio = [];



          this.otrasMateriasColegio = this.otrasMateriasColegio ? this.otrasMateriasColegio : [];

          if (_migradas) {
            const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => any) =>
              array.reduce((acc, value, index, array) => {
                (acc[predicate(value, index, array)] ||= []).push(value);
                return acc;
              }, {} as { [key: string]: T[] });

            if (_migradas[0]?.materias) {
              const grouped = groupBy(_migradas[0]?.materias, (materia: any) => materia.nombre);
              for (const [key, value] of Object.entries(grouped)) {
                migradas.push({
                  id: 36,
                  area: null,
                  nombre: key,
                  materias: value
                });
              }
              this.migradas = migradas.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
            }
          }

          // if (_otrasMateriasColegio) {
          //   const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => any) =>
          //     array.reduce((acc, value, index, array) => {
          //       (acc[predicate(value, index, array)] ||= []).push(value);
          //       return acc;
          //     }, {} as { [key: string]: T[] });

          //   if (_otrasMateriasColegio[0]?.materias) {
          //     const grouped = groupBy(_otrasMateriasColegio[0]?.materias, (materia: any) => materia.nombre);
          //     for (const [key, value] of Object.entries(grouped)) {
          //       otrasMateriasColegio.push({
          //         id: 46,
          //         area: null,
          //         nombre: key,
          //         materias: value
          //       });
          //     }
          //     this.otrasMateriasColegio =
          //       otrasMateriasColegio.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
          //   }
          // }

        }
      );
  }



  getOtrasMaterias() {
    this.materiaDataService.getOtrasMaterias().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.otrasMateriasNext(res);
        }
      );
  }

  getMateriasMaestras(isEdit) {
    this.materiaDataService.getMateriasMaestras(isEdit).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.materiasMaestrasNext(res);
        }
      );
  }

  getMateria(id: string) {
    // this.materiaNext(null);
    this.materiaDataService.getMateria(id, this.temasOriginales).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.materiaNext(res);
        }
      );
  }

  getMateriasByCurso(id: string): Observable<any> {
    return this.materiaDataService.getMateriasByCurso(id);
  }

  getAutoreMateriaCentro(id: string): Observable<any> {
    return this.materiaDataService.getAutoreMateriaCentro(id);
  }

  getLibrosAsignaciones(id: string): Observable<any> {
    return this.materiaDataService.getLibrosAsignaciones(id);
  }

  createMateria(materia: Materia) {
    this.materiaDataService.createMateria(materia).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getMisMaterias();

      });
  }

  createMateriaCentro(materia: any) {
    this.materiaDataService.createMateriaCentro(materia).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.localCacheService.expire('MATERIADATASERVICE-GETOTRASMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getOtrasMaterias();
        this.localCacheService.expire('MATERIADATASERVICE-GETASIGNATURAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getAsignaturas();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getMisMaterias();

      });
  }

  updateMateria(materia: Materia) {
    this.materiaDataService.updateMateria(materia).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getMateria(materia.id);
      });
  }

  updateMateriaCentro(id, materia: any) {
    this.materiaDataService.updateMateriaCentro(id, materia).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.localCacheService.expire('MATERIADATASERVICE-GETOTRASMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getOtrasMaterias();
        this.localCacheService.expire('MATERIADATASERVICE-GETASIGNATURAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getAsignaturas();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getMisMaterias();

      });
  }

  reordenarTemasMateria(materia: any) {
    this.materiaDataService.reordenarTemasMateria(materia).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.localCacheService.expire(`MATERIADATASERVICE-GETMATERIA-${materia.materiaId}`)
          .pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getMateria(materia.materiaId);
      });
  }

  updateMisMaterias(materias: any[]) {
    this.localCacheService.expire('MATERIADATASERVICE-GETASIGNATURAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
    this.materiaDataService.updateMisMaterias(materias).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getMisMaterias();

      });
  }

  deleteMateria(materia: Materia) {
    this.materiaDataService.deleteMateria(materia).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getMisMaterias();

      });
  }

  deleteMateriaCentro(materia: Materia) {
    this.materiaDataService.deleteMateriaCentro(materia).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.localCacheService.expire('MATERIADATASERVICE-GETOTRASMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getOtrasMaterias();
        this.localCacheService.expire('MATERIADATASERVICE-GETASIGNATURAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getAsignaturas();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getMisMaterias();


      });
  }

  copiarMateria(materia: Materia) {
    this.materiaDataService.copiarMateria(materia).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getMateria(this.materia.id);
      });
  }


  crearLibro(data: any) {
    this.materiaDataService.crearLibro(data).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getMisMaterias();

      });
  }

  crearLibroSecuencia(data: any): Observable<any> {
    return this.materiaDataService.crearLibro(data);
  }

  updateTituloLibro(libroId: Materia, libroTitulo: string) {
    this.materiaDataService.updateTituloLibro({ id: libroId, nombre: libroTitulo }).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getMisMaterias();

      });
  }

  asignarClasesLibro(materiaId: Materia, profesorId: string, asignaciones: any): Observable<any> {
    return this.materiaDataService.asignarClasesLibro({ materiaId, profesorId, asignaciones }).pipe(
      take(1),
      tap(() => {
        this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1));
        this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1));
        this.getMisMaterias();
        this.notificationService.success(this.translateService.instant('notificaciones.moodle.anadido'));
      }),
      takeUntil(this.destroy$)
    );
  }

  duplicarLibro(libroId: string, libroAlias: string): Observable<any> {
    return this.materiaDataService.duplicarLibro({ materiaId: libroId, nombre: libroAlias }).pipe(
      take(1),
      tap(() => {
        this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.getMisMaterias();
      }),
      takeUntil(this.destroy$)
    );
  }

  deleteLibro(libroId: any, libroValidacion: boolean = false) {
    return this.materiaDataService.deleteLibro(libroId, libroValidacion)
  }

  reloadMisMaterias() {
    this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
    this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
    this.getMisMaterias();
  }

  crearEnlace(materia: Materia) {
    this.materiaDataService.crearEnlace(materia).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getMateria(this.materia.id);
      });
  }



  getLocations(quitarTemaOrigen?: boolean, temaId?: string): Materia[] {
    const materias = [];
    const currentUserId = this.authManagementService.currentUser.persona.id;
    let _materias = false ? this.misLibros.map(materia => {
      materia.indiceTemas = materia.indiceTemas.filter(tema => tema.autor && tema.autor.id === currentUserId);
      return materia;
    }) : this.misLibros;

    _materias = this.misLibros?.reduce(function (r, a) {
      r[`${a.curso.ordinal} ${a.curso.etapa.acronimo}`.toString()] = r[`${a.curso.ordinal} ${a.curso.etapa.acronimo}`.toString()] || [];

      a.libros.forEach(libro => {
        libro.indiceTemas = libro.indiceTemas.filter(tema => {
          let value = tema.canEdit;
          if (quitarTemaOrigen) {
            value = value && tema.id !== temaId;
          }
          return value;
        });

        libro.indiceTemas.forEach(tema => {
          tema.indiceSeccionesVirtuales = JSON.parse(JSON.stringify(tema.indiceSecciones));
          let restaSeccion = 0;
          let restaSubSeccion = 0;
          let restaSubSubSeccion = 0;
          let restaSubSubSubSeccion = 0;

          tema.indiceSeccionesVirtuales.forEach((indiceSeccion, index) => {
            indiceSeccion.indiceOriginal = indiceSeccion.indice;
            if (indiceSeccion.indice.subseccion === 0 && indiceSeccion.indice.subsubseccion === 0) {
              if (index > 0 && tema.indiceSeccionesVirtuales[index].indice.seccion === (tema.indiceSeccionesVirtuales[index - 1].indice.seccion + 2)) {
                restaSubSeccion = 0;
                restaSubSubSeccion = 0;
              }

              if (indiceSeccion.visible !== 'Alumnos') {
                restaSeccion = restaSeccion + 1;
              }
              indiceSeccion.indice.seccion = indiceSeccion.indice.seccion - restaSeccion;
              indiceSeccion.indice.subsubseccion = 0;
              indiceSeccion.indice.subseccion = 0;
              indiceSeccion.indice.subsubsubseccion = 0; // Nuevo nivel: subsubsubseccion
            } else {
              // Lógica para subsección y subsubsección y subsubsubsección
              if (indiceSeccion.indice.subseccion >= 1 && indiceSeccion.indice.subsubseccion === 0) {
                if (index > 0 && tema.indiceSeccionesVirtuales[index].indice.seccion === (tema.indiceSeccionesVirtuales[index - 1].indice.seccion + 1)) {
                  restaSubSeccion = 0;
                  restaSubSubSeccion = 0;
                } else {
                  // es una subseccion
                  if (indiceSeccion.visible !== 'Alumnos') {
                    restaSubSeccion = restaSubSeccion + 1;
                  }
                }
                indiceSeccion.indice.seccion = indiceSeccion.indice.seccion - restaSeccion;
                indiceSeccion.indice.subseccion = indiceSeccion.indice.subseccion - restaSubSeccion;
                indiceSeccion.indice.subsubseccion = 0;
                indiceSeccion.indice.subsubsubseccion = 0; // Nuevo nivel: subsubsubseccion
              } else {
                if (indiceSeccion.indice.subsubseccion >= 1 && indiceSeccion.indice.subsubsubseccion === 0) {
                  if (index > 0 && tema.indiceSeccionesVirtuales[index].indice.subseccion === (tema.indiceSeccionesVirtuales[index - 1].indice.subseccion + 1)) {
                    restaSubSeccion = 0;
                    restaSubSubSeccion = 0;
                    restaSubSubSubSeccion = 0;
                  } else {
                    // es una subsubseccion
                    if (indiceSeccion.visible !== 'Alumnos') {
                      restaSubSubSubSeccion = restaSubSubSubSeccion + 1;
                    }
                  }
                  indiceSeccion.indice.seccion = indiceSeccion.indice.seccion - restaSeccion;
                  indiceSeccion.indice.subseccion = indiceSeccion.indice.subseccion - restaSubSeccion;
                  indiceSeccion.indice.subsubseccion = indiceSeccion.indice.subsubseccion - restaSubSubSeccion;
                  indiceSeccion.indice.subsubsubseccion = indiceSeccion.indice.subsubsubseccion - restaSubSubSubSeccion;
                  // indiceSeccion.indice.subsubsubseccion = (indiceSeccion.indice.subsubsubseccion || 0) + 1;

                } else {
                  if (indiceSeccion.visible !== 'Alumnos') {
                    restaSubSubSubSeccion = restaSubSubSubSeccion + 1;
                  }
                  indiceSeccion.indice.seccion = indiceSeccion.indice.seccion - restaSeccion;
                  indiceSeccion.indice.subseccion = indiceSeccion.indice.subseccion - restaSubSeccion;
                  indiceSeccion.indice.subsubseccion = indiceSeccion.indice.subsubseccion - restaSubSubSeccion;
                  indiceSeccion.indice.subsubsubseccion = indiceSeccion.indice.subsubsubseccion - restaSubSubSubSeccion;


                }
                // es una subsubseccion
              }
            }
          });
        });

        r[`${a.curso.ordinal} ${a.curso.etapa.acronimo}`.toString()].push(libro);
      });

      return r;
    }, Object.create(null));

    for (const k in _materias) {
      if (typeof _materias[k] !== 'function') {
        materias.push({
          name: k,
          materias: _materias[k]
        });
      }
    }

    return materias;
  }

  private otrasMateriasNext(materias: Materia[]) {
    this._otrasMaterias.next(materias);
  }

  private materiasMaestrasNext(materias: Materia[]) {
    this._materiasMaestras.next(materias);
  }

  private misMateriasNext(materias: Materia[]) {
    this._misMaterias.next(materias);
  }

  private misLibrosNext(materias: Materia[]) {
    this._misLibros.next(materias);
  }

  private materiasColegioNext(materias: Materia[]) {
    this._materiasColegio.next(materias);
  }

  private materiasNext(materias: Materia[]) {
    this._asignaturas.next(materias);
  }

  private materiaNext(materia: Materia) {
    this._materia.next(materia);
  }
}
