import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MateriaManagementService } from '../../../../services/data/materia/materia-management.service';
import { EnvService } from '../../../../services/env.service';
import { ColorHelper } from '../../../helper/color.helper';
import { TipoOrientacion } from '../../../../shared/models/tema/modulo.model';
import { DomSanitizer } from '@angular/platform-browser';

export enum PosicionPie {
  centrado  = 'Centrado',
  derecha   = 'Derecha'
}

@Component({
  selector: 'aula-planeta-imagen-destacada',
  templateUrl: 'imagen-destacada.component.html',
  styleUrls: ['./imagen-destacada.component.scss'],
})
export class ImagenDestacadaComponent implements OnInit {

  imgSrc: any;
  previousImgSrc: any;
  imgToRender: any;
  color: string;
  proporcion: string;
  posicionPie: string;
  visualizacion: number;
  twoImages = false;

  constructor(
    private materiaManagementService: MateriaManagementService,
    public dialogRef: MatDialogRef<ImagenDestacadaComponent>,
    public colorHelper: ColorHelper,
    public envService: EnvService,
    @Inject(MAT_DIALOG_DATA) public data,
    public sanitizer: DomSanitizer
  ) {

  }

  ngOnInit () {
    this.imgSrc = this.data.img.src;
    this.imgToRender = this.data.img.src;
    if (this.data.img.modulo.propiedades.imagen_previa) {
      this.previousImgSrc = this.data.img.modulo.propiedades.imagen_previa;
      this.twoImages = true;
    }
    this.color = this.materiaManagementService.materia.color;
    this.proporcion = this.data.img.proporcion;
    this.visualizacion = this.data.img.componentVisible;
    this.setPosicionPie();
    console.log(this.proporcion, this.data.img, this.posicionPie);
  }

  setPosicionPie() {
    if (this.proporcion === (TipoOrientacion.horizontal || TipoOrientacion.muyHorizontal)) {
      this.posicionPie = PosicionPie.centrado;
      return;
    } else if (this.proporcion === (TipoOrientacion.vertical || TipoOrientacion.muyVertical)) {
      this.posicionPie = PosicionPie.derecha;
      return;
    }

    this.posicionPie = this.data.img.modulo.propiedades?.data_orientacionpie ?
                          this.data.img.modulo.propiedades?.data_orientacionpie :
                              PosicionPie.centrado;
  }

  // changeImg () {
  //   if (this.imgToRender.includes(this.imgSrc)) {
  //     this.imgToRender = this.envService.rootContent + this.data.img.modulo.propiedades.imagen_previa;
  //   } else {
  //     this.imgToRender = this.envService.rootContent + this.data.img.src;
  //   }
  // }
}
