import { Grupo } from './../../../shared/models/grupo.model';
import { Curso } from './../../../shared/models/curso.model';
import { Alumno } from './../../../shared/models/alumno.model';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GestionAlumnoDataService } from './gestion-alumno-data.service';
import { take, takeUntil } from 'rxjs/operators';
import { MisClases } from '../../../shared/models/mis-clases.model';
import { MisGrupos } from '../../../shared/models/mis-grupos.model';
import { MisGruposPersonalizados } from '../../../shared/models/mis-grupos-personalizados.model';
import { GtagService } from '../../gtag/gtag.service';

@Injectable({
  providedIn: 'root'
})
export class GestionAlumnoManagementService implements OnDestroy {

  misAlumnos$: Observable<Alumno[]>;
  misClases$: Observable<MisClases[]>;
  cursosDelCentro$: Observable<Curso[]>;
  gruposDelCentro$: Observable<Grupo[]>;
  misGrupos$: Observable<MisGrupos[]>;
  misGruposPersonalizados$: Observable<MisGruposPersonalizados[]>;
  misClasesClassroom$: Observable<any[]>;
  misClasesTeams$: Observable<any[]>;


  private _misAlumnos: BehaviorSubject<Alumno[]> = new BehaviorSubject<Alumno[]>([]);
  private _misClases: BehaviorSubject<MisClases[]> = new BehaviorSubject<MisClases[]>([]);
  private _cursosDelCentro: BehaviorSubject<Curso[]> = new BehaviorSubject<Curso[]>([]);
  private _gruposDelCentro: BehaviorSubject<Grupo[]> = new BehaviorSubject<Grupo[]>([]);
  private _misGrupos: BehaviorSubject<MisGrupos[]> = new BehaviorSubject<MisGrupos[]>([]);
  private _misGruposPersonalizados: BehaviorSubject<MisGruposPersonalizados[]> = new BehaviorSubject<MisGruposPersonalizados[]>([]);

  private _misClasesClassroom: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private _misClasesTeams: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private destroy$: Subject<void> = new Subject<void>();

  noResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private gestionAlumnoDataService: GestionAlumnoDataService,
    private gtagService: GtagService
  ) {
    this.misAlumnos$ = this._misAlumnos.asObservable();
    this.misClases$ = this._misClases.asObservable();
    this.cursosDelCentro$ = this._cursosDelCentro.asObservable();
    this.gruposDelCentro$ = this._gruposDelCentro.asObservable();
    this.misGrupos$ = this._misGrupos.asObservable();
    this.misGruposPersonalizados$ = this._misGruposPersonalizados.asObservable();
    this.misClasesClassroom$ = this._misClasesClassroom.asObservable();
    this.misClasesTeams$ = this._misClasesTeams.asObservable();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get misAlumnos() {
    return this._misAlumnos.getValue();
  }

  get misClases() {
    return this._misClases.getValue();
  }

  get misClasesClassroom() {
    return this._misClasesClassroom.getValue();
  }

  get misClasesTeams() {
    return this._misClasesTeams.getValue();
  }

  get cursosDelCentro() {
    return this._cursosDelCentro.getValue();
  }

  get gruposDelCentro() {
    return this._gruposDelCentro.getValue();
  }

  get misGrupos() {
    return this._misGrupos.getValue();
  }

  get misGruposPersonalizados() {
    return this._misGruposPersonalizados.getValue();
  }

  set misAlumnos(misAlumnos: Alumno[]) {
    this._misAlumnos.next(misAlumnos);
  }

  set misClasesClassroom(misClasesClassroom: any[]) {
    this._misClasesClassroom.next(misClasesClassroom);
  }

  set misClasesTeams(misClasesTeams: any[]) {
    this._misClasesTeams.next(misClasesTeams);
  }

  set cursosDelCentro(cursosDelCentro: Curso[]) {
    this._cursosDelCentro.next(cursosDelCentro);
  }

  set gruposDelCentro(gruposDelCentro: Grupo[]) {
    this._gruposDelCentro.next(gruposDelCentro);
  }

  set misGrupos(misGrupo: MisGrupos[]) {
    this._misGrupos.next(misGrupo);
  }

  set misGruposPersonalizados(misGrupoPersonalidados: MisGruposPersonalizados[]) {
    this._misGruposPersonalizados.next(misGrupoPersonalidados);
  }

  getMisAlumnos() {
    this.gestionAlumnoDataService.getMisAlumnos().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.misAlumnosNext(res);
        }
      );
  }

  getMisClases() {
    this.gestionAlumnoDataService.getMisClases().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.misClasesNext(res);
        }
      );
  }

  getMisClasesClassroom() {
    this.gestionAlumnoDataService.getMisClasesClassroom().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.misClasesClassroomNext(res);
        }
      );
  }

  getMisClasesTeams() {
    this.gestionAlumnoDataService.getMisClasesTeams().pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(
      res => {
        this.misClasesTeamsNext(res);
      }
    );
  }

  updateMisClases(misClases: any[]) {
    this.gestionAlumnoDataService.updateMisClases(misClases).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.getMisClases();
        }
      );
  }

  updateMiClase(clase) {
    return this.gestionAlumnoDataService.updateMiClase(clase).pipe(
      take(1),
      takeUntil(this.destroy$))
  }

  getCursosGrupos() {
    this.gestionAlumnoDataService.getCursosDelCentro().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.cursosDelCentroNext(res.cursos);
          this.gruposDelCentroNext(res.grupos);
        }
      );
  }


  getMisGrupos() {
    this.gestionAlumnoDataService.getMisGrupos().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.misGruposNext(res);
        }
      );
  }

  getMisGruposPersonalizados() {
    this.gestionAlumnoDataService.getMisGruposPersonalizados().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          res.forEach(grupo => {
            // grupo.clase.id = `${grupo.clase.curso.id}#${grupo.clase.grupo.id}`;
          });
          this.misGruposPersonalizadosNext(res);
        }
      );
  }

  createGrupo(grupo: any) {
    this.gestionAlumnoDataService.createGrupo(grupo).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.getMisGruposPersonalizados();
          this.getMisAlumnos();
        }
      );
  }

  updateGrupo(grupo: any) {
    this.gestionAlumnoDataService.updateGrupo(grupo).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.getMisGruposPersonalizados();
          this.getMisAlumnos();
        }
      );
  }

  deleteGrupo(idGrupo: string) {
    this.gestionAlumnoDataService.deleteGrupo(idGrupo).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.getMisGruposPersonalizados();
          this.getMisAlumnos();
        },
        error => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: error?.status,
              errorMessage: error?.statusText,
              popupId: 'borrar',
              popupName: 'popup_borrar'
            }
          };
          this.gtagService.event(info);
        }
      );
  }

  getListaAlumnosEnUnaClase(clase, alumnos): any {
    // eslint-disable-next-line arrow-body-style
    let _alumnos = [];
    _alumnos = alumnos.filter(alumno => {
      console.log('-----------');

      console.log('alumno.clase.curso.id:', alumno.clase.curso.id);
      console.log('alumno.clase.grupo.id:', alumno.clase.grupo.id);
      console.log('clase.curso.id:', clase.curso.id);
      console.log('clase.grupo.id:', clase.grupo.id);

      return (alumno.clase.curso.id === clase.curso.id && alumno.clase.grupo.id === clase.grupo.id);
    });

    return _alumnos;
  }

  private misAlumnosNext(misAlumnos: Alumno[]) {
    this._misAlumnos.next(misAlumnos);
  }

  private misClasesNext(misClases: MisClases[]) {
    this._misClases.next(misClases);
  }

  private misClasesClassroomNext(misClasesClassroom: any[]) {
    this._misClasesClassroom.next(misClasesClassroom);
  }

  private misClasesTeamsNext(misClasesTeams: any[]) {
    this._misClasesTeams.next(misClasesTeams);
  }

  private cursosDelCentroNext(cursosDelCentro: Curso[]) {
    this._cursosDelCentro.next(cursosDelCentro);
  }

  private gruposDelCentroNext(gruposDelCentro: Grupo[]) {
    this._gruposDelCentro.next(gruposDelCentro);
  }

  private misGruposNext(misGrupo: MisGrupos[]) {
    this._misGrupos.next(misGrupo);
  }

  private misGruposPersonalizadosNext(misGrupoPersonalizados: MisGruposPersonalizados[]) {
    this._misGruposPersonalizados.next(misGrupoPersonalizados);
  }

}
