<div id="dialog-incidencias" class="standard d-flex flex-column">
    <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ data.titulo }}</h1>
    <span class="mb-4">{{data.descripcionFormulario}}</span>

     <div mat-dialog-content class="mat-dialog-content ">
      <form [formGroup]="form">
        <fieldset class="w-100 d-flex flex-column">
          <div class="form-group default-input mb-4 me-3">
            <label for="asunto">{{data.asunto}} <span><small>*</small> </span></label>
            <input id="asunto" type="text" class="form-control" formControlName="asunto" cdkFocusInitial>
            <mat-error *ngIf="form.controls.asunto.touched && form.controls.asunto.errors?.required">
              {{data.errors.asunto.obligatorio}}
            </mat-error>
            <mat-error *ngIf="form.controls.asunto.touched && form.controls.asunto.errors?.maxlength">
              {{data.errors.asunto.length}}
            </mat-error>
          </div>

          <div class="form-group default-textarea mb-4 pe-3">
            <label for="descripcion">{{data.incidencia}} <span><small>*</small> </span></label>
            <textarea id="descripcion" class="form-control" formControlName="descripcion" maxlength="400"></textarea>
            <mat-error *ngIf="form.controls.descripcion.touched && form.controls.descripcion.errors?.required">
              {{data.errors.descripcion.obligatorio}}
            </mat-error>
          </div>

          <div class="d-flex flex-column form-group default-dropzone mb-3">
            <label>Imagen o Video (máximo 3MB)</label>
            <div class="d-flex flex-column flex-md-row align-items-center">
              <div (click)="imageField.click()" class="w-100 default-dropzone__container d-flex align-items-center justify-content-center" [class.loaded]="form.get('imagen').value">
                <div *ngIf="!form.get('imagen').value" class="avatar d-flex align-items-center justify-content-center">
                  <div class="avatar text-center">
                    <mat-icon>cloud_upload</mat-icon>
                  </div>
                </div>
                <div *ngIf="form.get('imagen').value" class="image-preview">
                  <img [src]="form.get('imagen').value" alt="Image preview" class="img-thumbnail">
                </div>
                   
           
              </div>
              
              <div class="w-60 ms-4 p-0 d-flex flex-column default-dropzone__text">
                <p class="mb-0">
                  <a [matMenuTriggerFor]="menu">{{ form.get('imagen').value ? 'Eliminar captura de pantalla' : 'Añadir un fichero' }}</a>
                  <br *ngIf="form.get('imagen').value">
                  <small *ngIf="form.get('imagen').value">{{ fileName }}</small>
                  
                </p>
                <input type="file" name="file" #imageField (change)="onDrop($event.target.files[0])" hidden>
                <mat-menu #menu="matMenu" class="default-dropdown">
                  <button mat-menu-item (click)="imageField.click()">
                    <mat-icon class="material-icons-filled">portrait</mat-icon>
                    Seleccionar fichero
                  </button>
                  <button [disabled]="!form.get('imagen').value" mat-menu-item (click)="deleteFile($event)">
                    <mat-icon class="material-icons-filled">delete</mat-icon>
                    Borrar fichero
                  </button>
                </mat-menu>
                <p class="mb-0" *ngIf="!form.get('imagen').value">Máximo 3 MB</p>

              <div class="w-75">
                <p class="format_types_title mb-0 pb-0">{{'dialogs.incidencias.formatos-soportados' | translate}}</p>
                <p class="format_types mb-0">.mp4, .quicktime, .x-flv, .mpeg, .gif, .jpeg, .jpg, .png</p>
               
              </div>          

                
                
              </div>
            </div>
          </div>

          <div class="form-group default-input mb-4 me-3">
            <label for="email">{{data.email}}</label>
            <p>{{data.mensajeEmail}}</p>
            <input id="email" type="email" class="form-control" formControlName="email" cdkFocusInitial>
            <mat-error *ngIf="form.controls.email.touched && form.controls.email.errors?.required">
              {{data.errors.email.obligatorio}}
            </mat-error>
            <mat-error *ngIf="form.controls.email.touched && form.controls.email.errors?.email">
              {{data.errors.email.validez}}
            </mat-error>
          </div>
        </fieldset>
      </form>
    </div>
    <span id="campos-obligatorios" class="my-3"><small>{{data.camposObligatorios}}</small></span>

    <div mat-dialog-actions>
      <div class="d-flex justify-content-between">
        <button (click)="onCancel()" class="default-button default-button__primary me-3">{{'generico.cancelar' | translate}}</button>
        <button class="default-button default-button__secondary me-3" (click)="onSubmit()">{{'generico.enviar' | translate}}</button>
      </div>
    </div>
  </div>
