import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

interface Scripts {
    name: string;
    src: string;
}

declare global {
    interface Window {
        dataLayer: any;
    }
}

const ScriptStore: Scripts[] = [
    { name: 'gtagmanager', src: `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics}` },
];

declare let document: any;

@Injectable({ providedIn: 'root' })
export class ScriptService {

    private scripts: any = {};

    constructor() {
        ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });
    }

    init() {
        this.load();
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push('config', environment.googleAnalytics, { 'send_page_view': false });
        // Google Tag Manager
        (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js', config: i });
            let f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', environment.googleAnalytics);
        // Userguiding
        // (function (g, u, i, d, e, s) {
        //     g[e] = g[e] || [];
        //     const f = u.getElementsByTagName(i)[0];
        //     const k = u.createElement(i);
        //     k.async = true;
        //     k.src = 'https://static.userguiding.com/media/user-guiding-' + s + '-embedded.js';
        //     f.parentNode.insertBefore(k, f);
        //     if (g[d]) return;
        //     const ug = g[d] = { q: [] } as any;
        //     ug.c = function (n) { return function () { ug.q.push([n, arguments]) }; };
        //     const m = ['previewGuide', 'finishPreview', 'track', 'identify', 'hideChecklist', 'launchChecklist'];
        //     for (let j = 0; j < m.length; j += 1) { ug[m[j]] = ug.c(m[j]); }
        // })(window, document, 'script', 'userGuiding', 'userGuidingLayer', '516726976ID');
    }

    load() {
        let promises: any[] = [];
        ScriptStore.forEach((script) => promises.push(this.loadScript(script.name)));
        return Promise.all(promises);
    }

    loadScript(name: string) {
        return new Promise((resolve, reject) => {
            // resolve if already loaded
            if (this.scripts[name].loaded) {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            }
            else {
                // load script
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                if (script.readyState) {  //IE
                    script.onreadystatechange = () => {
                        if (script.readyState === "loaded" || script.readyState === "complete") {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {  //Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }

}