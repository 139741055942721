<div id="dialog-add-recurso" class="standard">
    <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ 'dialogs.add-recurso.title' | translate }}</h1>
    <div mat-dialog-content class="mat-dialog-content">
        <mat-horizontal-stepper [linear]="true" #stepper class="default-stepper" (selectionChange)="onStepperChange($event)">
            <mat-step [stepControl]="firstStep" label="{{ 'dialogs.add-recurso.step1' | translate}}">
                <form role="form" [formGroup]="firstStep" class="mt-4 d-flex flex-column flex-md-row">
                    <div class="w-100 me-4">
                        <h2 class="custom-text mb-4">{{ 'dialogs.add-recurso.step1-title' | translate }}</h2>
                        <p class="mb-5">{{ 'dialogs.add-recurso.step1-text' | translate }}</p>
                    </div>

                    <mat-radio-group class="d-flex flex-column radio-list mt-5 w-100" role="list" formControlName="sectionType">
                        <mat-radio-button role="listitem" class="mb-3" [value]="1" checked [disableRipple]="true">
                            <div class="d-flex flex-column ms-2">
                                <span>{{ 'dialogs.add-recurso.step1.recurso1.title' | translate}}</span>
                                <small class="text-break grey-text__light text__small">{{ 'dialogs.add-recurso.step1.recurso1.hint' | translate}}</small>
                            </div>
                        </mat-radio-button>
                        <mat-radio-button role="listitem" class="mb-3" [value]="2" [disableRipple]="true">
                            <div class="d-flex flex-column ms-2">
                                <span>{{ 'dialogs.add-recurso.step1.recurso2.title' | translate}}</span>
                                <small class="text-break grey-text__light text__small">{{ 'dialogs.add-recurso.step1.recurso2.hint' | translate}}</small>
                            </div>
                        </mat-radio-button>
                        <mat-radio-button role="listitem" [value]="3" [disableRipple]="true">
                            <div class="d-flex flex-column ms-2">
                                <span>{{ 'dialogs.add-recurso.step1.recurso3.title' | translate}}</span>
                                <small class="text-break grey-text__light text__small">{{ 'dialogs.add-recurso.step1.recurso3.hint' | translate}}</small>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                </form>
            </mat-step>

            <mat-step [stepControl]="secondStep" label="{{ 'dialogs.add-recurso.step2' | translate}}">
                <ng-container *ngIf="firstStep.controls['sectionType'].value === 1">
                    <h2 class="custom-text my-4">{{ 'dialogs.add-recurso.step2.recurso1.title' | translate }}</h2>
                    <mat-tab-group>
                        <mat-tab label="{{'dialogs.add-recurso.step2.recurso1.tab1' | translate}}">
                            <div class="d-flex justify-content-end align-items-end filters-container mb-0">
                                <!-- FILTRO -->
                                <!--div class="d-flex">
                                    <div class="d-flex align-items-center selector me-4">
                                        <div class="pe-0 pt-2">
                                            <mat-select class="custom-arrow" #selectorProgreso [(value)]="tipoRecurso" (selectionChange)="filtrarRecursos()">
                                                <mat-option *ngFor="let criterio of criteriosTipo" class="default-option" [value]="criterio.value">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span>{{'generico.filtros.' + criterio.value | translate}}</span>
                                                        <mat-icon class="right-icon" [hidden]="criterio.value !== tipoRecurso">check</mat-icon>
                                                    </div>
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="pt-0 ps-3 selector-text">
                                            <span>{{ 'generico.filtros.' + tipoRecurso | translate }}</span>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center selector">
                                        <div class="pe-0 pt-2">
                                            <mat-select class="custom-arrow" #selectorProgreso [(value)]="ordenRecurso" (selectionChange)="filtrarRecursos()">
                                                <mat-option *ngFor="let criterio of criteriosOrden" class="default-option" [value]="criterio.value">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span>{{'generico.filtros.' + criterio.value | translate}}</span>
                                                        <mat-icon class="right-icon" [hidden]="criterio.value !== ordenRecurso">check</mat-icon>
                                                    </div>
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="pt-0 ps-3 selector-text">
                                            <span>{{ 'generico.filtros.' + ordenRecurso | translate }}</span>
                                        </div>
                                    </div>
                                </div-->

                                <!-- BUSCADOR -->
                                <div class="search-filter-input search-filter-input__blue d-flex align-items-center w-50 me-0 px-1" [ngClass]="filterControl.value ? 'search-filter-input__typing' : ''">
                                    <mat-icon class="ms-2 search-icon" matPrefix (click)="buscar(filterControl.value)">search</mat-icon>
                                    <input class="ms-2" matInput type="text" (keyup.enter)="buscar(filterControl.value)" placeholder="{{'generico.buscador.dialogo' | translate}}" #filterInput [formControl]="filterControl" />
                                    <div class="d-flex justify-content-end align-content-center ms-1 me-1">
                                        <button *ngIf="filterControl.value" class="me-1 custom-icon" [disabled]="!filterControl.value" mat-icon-button attr.aria-label="{{'tareas.filtro.reinicio' | translate}}" (click)="filterInput.value = ''; filterControl.setValue('');">
                                            <mat-icon [ngClass]="filterControl.value ? 'grey-text__lightest' : ''">close</mat-icon>
                                        </button>
                                        <!--button class="me-2 custom-icon" [disabled]="!filterControl.value" mat-icon-button aria-label="Filtrar" (click)="buscar(filterControl.value)">
                                            <mat-icon>filter_list</mat-icon>
                                        </button-->
                                    </div>
                                </div>
                            </div>
                            <div>
                                <form [formGroup]="secondStep">
                                    <div *ngIf="dataRecursos.length > 0" class="d-flex flex-column w-100 overflow-auto">
                                        <!--ITEMS-->
                                        <div *ngFor="let element of dataRecursos" class="recurso-item d-flex flex-row my-3">
                                            <div>
                                                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null " [checked]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)">
                                                </mat-checkbox>
                                            </div>
                                            <div [ngClass]="colorHelper.getColor(element.materiaColor)" class="w-100 d-flex flex-row p-2 ms-4 custom-bg__light border-rounded">
                                                <div class="w-35 d-flex flex-row align-items-start me-4">
                                                    <img [src]="element.imagenPrevia | safe:'url'" sanitize-img [type]="'default'" height="50" width="80" class="rect-img border-rounded__xs me-4">
                                                    <span class="title font-weight-bold">{{element.tituloPlano | truncateText:[35, '...']}}</span>
                                                </div>
                                                <div class="w-25 me-4 d-flex flex-column">
                                                    <span class="text__small grey-text__light">{{element.materiaNombre}}</span>
                                                    <span class="text__small grey-text__light">{{element.cursoAcronimo}}</span>
                                                </div>
                                                <div class="w-35 me-4 text__small" [innerHTML]="element.descripcionHtml | safe:'html'"></div>

                                                <div class="me-4 d-flex align-items-center">
                                                    <div class="bars">
                                                        <div class="bar" [ngClass]="element.dificultad >= 1 ? 'active' : ''"></div>
                                                        <div class="bar" [ngClass]="element.dificultad >= 2 ? 'active' : ''"></div>
                                                        <div class="bar" [ngClass]="element.dificultad > 2 ? 'active' : ''"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="no-results-base no-results-base__xl d-flex flex-column align-items-center" *ngIf="dataRecursos.length < 1">
                                        <ng-container>
                                            <mat-icon class="material-icons-round">sentiment_satisfied</mat-icon>
                                            <div class="w-25">
                                                <span>{{ 'seguimiento-alumno.buscador.tab.recursos.recurso.mensaje-void' | translate }}</span>
                                            </div>
                                        </ng-container>
                                    </div>
                                </form>
                            </div>
                        </mat-tab>
                        <!-- RP-69026 se quita temporalemente -->
                        <mat-tab *ngIf="false" label="{{'dialogs.add-recurso.step2.recurso1.tab2' | translate}}">
                            <div class="d-flex justify-content-between align-items-center filters-container mb-0">

                                <!-- FILTRO -->
                                <div class="d-flex">
                                    <div class="d-flex align-items-center selector me-4">
                                        <div class="pe-0 pt-2">
                                            <mat-select class="custom-arrow" #selectorProgreso [(value)]="tipoRecurso2" (selectionChange)="filtrarRecursosBanco()">
                                                <mat-option *ngFor="let criterio of criteriosTipo" class="default-option" [value]="criterio.value">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span>{{'generico.filtros.' + criterio.value | translate}}</span>
                                                        <mat-icon class="right-icon" [hidden]="criterio.value !== tipoRecurso2">check</mat-icon>
                                                    </div>
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="pt-0 ps-3 selector-text">
                                            <span>{{ 'generico.filtros.' + tipoRecurso2 | translate }}</span>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center selector">
                                        <div class="pe-0 pt-2">
                                            <mat-select class="custom-arrow" #selectorProgreso [(value)]="ordenRecurso2" (selectionChange)="filtrarRecursosBanco()">
                                                <mat-option *ngFor="let criterio of criteriosOrden" class="default-option" [value]="criterio.value">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span>{{'generico.filtros.' + criterio.value | translate}}</span>
                                                        <mat-icon class="right-icon" [hidden]="criterio.value !== ordenRecurso2">check</mat-icon>
                                                    </div>
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="pt-0 ps-3 selector-text">
                                            <span>{{ 'generico.filtros.' + ordenRecurso2 | translate }}</span>
                                        </div>
                                    </div>
                                </div>

                                <!-- BUSCADOR -->
                                <div class="search-filter-input search-filter-input__blue d-flex align-items-center w-50 me-0 px-1" [ngClass]="filter2 ? 'search-filter-input__typing' : ''">
                                    <mat-icon class="ms-2 search-icon" matPrefix (click)="filter2 = filterInput2.value; dataRecursosBanco = search(recursosBanco, filter2)">search</mat-icon>
                                    <input class="ms-2" matInput type="text" (keyup)="filter2 = filterInput2.value; dataRecursosBanco = search(recursosBanco, filter2)" placeholder="{{'generico.buscador.dialogo' | translate}}" #filterInput2 [formControl]="filterControl" />
                                    <div class="d-flex justify-content-end align-content-center ms-1 me-1">
                                        <button *ngIf="filter2" class="me-1 custom-icon" [disabled]="!filter2" mat-icon-button attr.aria-label="{{'tareas.filtro.reinicio' | translate}}" (click)="filter2='';filterInput2.value=''; dataRecursosBanco = search(recursosBanco, filter2)">
                                            <mat-icon [ngClass]="filter2 ? 'grey-text__lightest' : ''">close</mat-icon>
                                        </button>
                                        <button class="me-2 custom-icon" [disabled]="!filter2" mat-icon-button aria-label="Filtrar" (click)="filter2 = filterInput2.value; dataRecursosBanco = search(recursosBanco, filter2)">
                                            <mat-icon>filter_list</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <form [formGroup]="secondStep">
                                    <div class="d-flex flex-column w-100 overflow-auto">
                                        <!--ITEMS-->
                                        <div *ngFor="let element of dataRecursosBanco" class="recurso-item grey-border d-flex flex-row py-2">
                                            <div>
                                                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null " [checked]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)">
                                                </mat-checkbox>
                                            </div>
                                            <div class="standard w-100 d-flex flex-row p-2 ms-4 border-rounded">
                                                <div class="w-40 d-flex flex-row align-items-start me-4">
                                                    <img [src]="element.miniatura | safe:'url'"  alt="" height="50" width="80" class="rect-img border-rounded__xs me-4">
                                                    <span class="text__small font-weight-bold custom-text">{{element.titulo | truncateText:[60, '...']}}</span>
                                                </div>
                                                <div class="w-60 me-4 text__small">{{element.descripcion | truncateText:[220, '...']}}</div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </mat-tab>
                    </mat-tab-group>
                </ng-container>

                <ng-container *ngIf="firstStep.controls['sectionType'].value === 2">
                    <h2 class="custom-text my-4">{{ 'dialogs.add-recurso.step2.recurso2.title' | translate }}</h2>

                    <div class="d-flex flex-row">

                        <div class="w-50 d-flex flex-colum pe-5 me-5">
                            <!-- LIST -->
                            <div class="overflow-auto data-table-container w-100 ">

                                <form [formGroup]="secondStep" class="mt-0 mb-4">
                                    <mat-selection-list #plantillaRecurso [multiple]="false" class="default-selected-list pt-0">
                                        <mat-list-option (click)="selectPlantilla(plantilla)" *ngFor="let plantilla of plantillasRecursos" [value]="plantilla" class="text__small grey-text__light">
                                            {{plantilla.name}}
                                        </mat-list-option>
                                    </mat-selection-list>
                                </form>
                            </div>
                        </div>

                        <div class="w-50 mb-4">
                            <div class="w-100 d-flex justify-content-center align-items-center border-rounded">
                                <img class="border-rounded" [src]="'./assets/images/plantillas/'+urlImg+'.png'" height="290px">
                            </div>
                        </div>
                    </div>

                </ng-container>

                <ng-container *ngIf="firstStep.controls['sectionType'].value === 3">
                    <h2 class="custom-text my-4">{{ 'dialogs.add-recurso.step2.recurso3.title' | translate }}</h2>
                    <form role="form" [formGroup]="secondStep" class="empty-section d-flex flex-row">
                        <div class="w-35 me-4">
                            <div class="form-group default-input">
                                <label class="default-text">{{ 'dialogs.add-recurso.step2.recurso3.form.type' | translate }}</label>
                                <mat-select (selectionChange)="changeTipoRecurso($event)" formControlName="type" class="default-select default-select__blue" [placeholder]="'dialogs.add-recurso.step2.recurso3.form.type' | translate" panelClass="cdkSelect">
                                    <ng-container *ngFor="let type of typeRecursos">
                                        <mat-option class="default-option" [value]="type.value">
                                            {{ type.viewValue }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </div>
                        </div>

                        <div *ngIf="secondStep.controls['type'].value" class="w-100 d-flex flex-column">
                            <div class="form-group default-input mb-0">
                                <label>{{'dialogs.add-recurso.step2.recurso3.form.title' | translate}}</label>
                                <input type="text" class="form-control" [placeholder]="('dialogs.add-recurso.step2.recurso3.form.title' | translate)" formControlName="title">
                                <div class="d-flex justify-content-between align-items-start mt-2">
                                    <mat-error class="mt-0" [ngClass]="secondStep.get('title').hasError('required') && secondStep.controls['title'].dirty ? 'visible' : 'invisible'">
                                        {{'generico.required' | translate}}
                                    </mat-error>
                                </div>
                            </div>

                            <div class="form-group default-textarea mb-0">
                                <label>{{'dialogs.add-recurso.step2.recurso3.form.description' | translate}}</label>
                                <textarea type="text" class="form-control" [placeholder]="('dialogs.add-recurso.step2.recurso3.form.description' | translate)" [maxlength]="textLimit" formControlName="description"></textarea>
                                <div class="d-flex justify-content-between align-items-start mt-2">
                                    <mat-error class="mt-0" [ngClass]="secondStep.get('description').hasError('required') && secondStep.controls['description'].dirty ? 'visible' : 'invisible'">
                                        {{'generico.required' | translate}}
                                    </mat-error>
                                    <span class="grey-text__light text__small">{{secondStep.controls['description'].value.length}}/{{textLimit}}</span>
                                </div>
                            </div>

                            <div *ngIf="secondStep.controls['type'].value === 1" class="d-flex flex-row">
                                <div class="w-100 form-group default-input mb-0 me-3">
                                    <label>{{'dialogs.add-recurso.step2.recurso3.form.url' | translate}}</label>
                                    <input type="text" class="form-control" [placeholder]="('dialogs.add-recurso.step2.recurso3.form.url' | translate)" formControlName="url">
                                    <div class="d-flex justify-content-between align-items-start mt-2">
                                        <mat-error class="mt-0" [ngClass]="secondStep.get('url').hasError('required') && secondStep.controls['url'].dirty ? 'visible' : 'invisible'">
                                            {{'generico.required' | translate}}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="secondStep.controls['type'].value === 2" class="d-flex flex-column form-group default-dropzone mb-4">
                                <label>{{ 'dialogs.add-recurso.step2.recurso3.form.file' | translate }}</label>
                                <div class="d-flex flex-column flex-md-row align-items-center">
                                    <div (dropZone)="onDropFile($event, 'documento')" (click)="fileField.click()" class="w-100 default-dropzone__container d-flex align-items-center justify-content-center">
                                        <mat-icon *ngIf="!secondStep.get('file').value" class="custom-icon icon">cloud_upload</mat-icon>
                                        <mat-icon *ngIf="secondStep.get('file').value" class="custom-icon icon">cloud_done</mat-icon>
                                    </div>
                                    <div class="w-60 ms-4 p-0 d-flex flex-column default-dropzone__text">
                                        <p class="mb-0">{{'dialogs.adjuntar-fichero.upload-info' | translate}}<a [matMenuTriggerFor]="menu">{{secondStep.get('file').value ? 'cambia' : 'añade' }}</a>{{'dialogs.adjuntar-fichero.upload-info2' | translate}}</p>
                                        <input type="file" name="file" #fileField (change)="onDropFile($event.target.files[0], 'documento')" hidden>
                                        <mat-menu #menu="matMenu" class="default-dropdown">
                                            <button mat-menu-item (click)="fileField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>Seleccionar fichero</button>
                                            <button [disabled]="!secondStep.get('file').value" mat-menu-item (click)="deleteFile()"> <mat-icon class="material-icons-filled">delete</mat-icon>Borrar fichero</button>
                                        </mat-menu>
                                        <p class="mb-0">{{'dialogs.adjuntar-fichero.ayuda' | translate}}</p>
                                    </div>
                                </div>
                                <mat-error class="danger-text text__small mt-2" *ngIf="error.documento">
                                    {{'generico.formulario.fichero-novalid' | translate}}
                                </mat-error>
                            </div>

                            <div *ngIf="secondStep.controls['type'].value === 3" class="d-flex flex-row">
                                <div class="w-100 form-group default-input mb-0">
                                    <label>{{'dialogs.add-recurso.step2.recurso3.form.embeded' | translate}}</label>
                                    <input type="text" class="form-control" [placeholder]="('dialogs.add-recurso.step2.recurso3.form.embeded' | translate)" formControlName="embeded">
                                    <div class="d-flex justify-content-between align-items-start mt-2">
                                        <mat-error class="mt-0" [ngClass]="secondStep.get('embeded').hasError('required') && secondStep.controls['embeded'].dirty ? 'visible' : 'invisible'">
                                            {{'generico.required' | translate}}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="secondStep.controls['type'].value === 4" class="d-flex flex-column form-group default-dropzone mb-4">

                                <form role="form" [formGroup]="secondStep" class="empty-section d-flex flex-row">
                                    <div class="w-100 d-flex flex-column">

                                        <div class="form-group default-input mb-3 default-dropzone">
                                            <label>{{ 'dialogs.add-module-base.form.file-image' | translate }}</label>
                                            <div class="d-flex flex-column flex-md-row align-items-center">
                                                <div (dropZone)="onDropImg($event, 'imagen')" (click)="imageField.click()" class="w-100 default-dropzone__container d-flex align-items-center justify-content-center">
                                                    <div class="avatar d-flex align-items-center justify-content-center">
                                                        <div *ngIf="(imagen$ | async) as foto; else nube" class="avatar text-center">
                                                            <img [src]="foto" alt="foto perfil">
                                                        </div>
                                                        <ng-template #nube>
                                                            <div class="avatar text-center">
                                                                <mat-icon>cloud_upload</mat-icon>
                                                            </div>
                                                        </ng-template>

                                                    </div>
                                                </div>

                                                <div class="w-60 ms-4 p-0 d-flex flex-column default-dropzone__text">
                                                    <p class="mb-0">{{'dialogs.adjuntar-fichero.upload-info' | translate}}<a [matMenuTriggerFor]="menu">{{secondStep.get('imagen').value ? 'cambia' : 'añade' }}</a>{{'dialogs.adjuntar-fichero.upload-info2' | translate}}</p>
                                                    <input type="file" name="file" #imageField (change)="onDropImg($event.target.files[0], 'imagen')" hidden>
                                                    <mat-menu #menu="matMenu" class="default-dropdown">
                                                        <button mat-menu-item (click)="imageField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>Seleccionar fichero</button>
                                                        <button [disabled]="!secondStep.get('imagen').value" mat-menu-item (click)="deleteImg('imagen')"> <mat-icon class="material-icons-filled">delete</mat-icon>Borrar fichero</button>
                                                    </mat-menu>
                                                    <p class="mb-0">{{'dialogs.adjuntar-fichero.ayuda' | translate}}</p>
                                                </div>
                                            </div>
                                            <mat-error class="danger-text text__small mt-2" *ngIf="error.imagen">
                                                {{'generico.formulario.fichero-novalid' | translate}}
                                            </mat-error>
                                        </div>

                                        <div class="form-group default-input mt-2 mb-3 default-dropzone">
                                            <label>{{ 'dialogs.add-module-base.form.file-image-prev' | translate }}</label>
                                            <div class="d-flex flex-column flex-md-row align-items-center">
                                                <div (dropZone)="onDropImg($event, 'imagen_previa')" (click)="imagePrevField.click()" class="w-100 default-dropzone__container d-flex align-items-center justify-content-center">
                                                    <div class="avatar d-flex align-items-center justify-content-center">
                                                        <div *ngIf="(imagen_previa$ | async) as foto; else nube" class="avatar text-center">
                                                            <img [src]="foto" alt="foto perfil">
                                                        </div>
                                                        <ng-template #nube>
                                                            <div class="avatar text-center">
                                                                <mat-icon>cloud_upload</mat-icon>
                                                            </div>
                                                        </ng-template>

                                                    </div>
                                                </div>

                                                <div class="w-60 ms-4 p-0 d-flex flex-column default-dropzone__text">
                                                    <p class="mb-0">{{'dialogs.adjuntar-fichero.upload-info' | translate}}<a [matMenuTriggerFor]="menuPrevio">{{secondStep.get('imagen_previa').value ? 'cambia' : 'añade' }}</a>{{'dialogs.adjuntar-fichero.upload-info2'
                                                        | translate}}
                                                    </p>
                                                    <input type="file" name="file" #imagePrevField (change)="onDropImg($event.target.files[0], 'imagen_previa')" hidden>
                                                    <mat-menu #menuPrevio="matMenu" class="default-dropdown">
                                                        <button mat-menu-item (click)="imagePrevField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>Seleccionar fichero</button>
                                                        <button [disabled]="!secondStep.get('imagen_previa').value" mat-menu-item (click)="deleteImg('imagen_previa')"> <mat-icon class="material-icons-filled">delete</mat-icon>Borrar fichero</button>
                                                    </mat-menu>
                                                    <p class="mb-0">{{'dialogs.adjuntar-fichero.ayuda' | translate}}</p>
                                                </div>
                                            </div>
                                            <mat-error class="danger-text text__small mt-2" *ngIf="error.imagen_previa">
                                                {{'generico.formulario.fichero-novalid' | translate}}
                                            </mat-error>
                                        </div>

                                        <div class="form-group default-input mt-2 mb-3">
                                            <label>{{'dialogs.add-module-base.form.texto_alternativo' | translate}}</label>
                                            <input type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.texto_alternativo' | translate)" formControlName="texto_alternativo">
                                            <div class="d-flex justify-content-between align-items-start mt-2">
                                                <mat-error class="mt-0 visible mb-3" *ngIf="secondStep.get('texto_alternativo').hasError('required') && secondStep.controls['texto_alternativo'].dirty">
                                                    {{'generico.required' | translate}}
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div class="form-group default-input mt-2 mb-3">
                                            <label>{{'dialogs.add-module-base.form.pie_de_imagen' | translate}}</label>
                                            <input type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.pie_de_imagen' | translate)" formControlName="pie_de_imagen">
                                            <div class="d-flex justify-content-between align-items-start mt-2">
                                                <mat-error class="mt-0 visible mb-3" *ngIf="secondStep.get('pie_de_imagen').hasError('required') && secondStep.controls['pie_de_imagen'].dirty">
                                                    {{'generico.required' | translate}}
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div class="form-group default-input mt-2 mb-3">
                                            <label>{{'dialogs.add-module-base.form.creditos' | translate}}</label>
                                            <input type="text" class="form-control" [placeholder]="('dialogs.add-module-base.form.creditos' | translate)" formControlName="creditos_html">
                                            <div class="d-flex justify-content-between align-items-start mt-2">
                                                <mat-error class="mt-0 visible mb-3" *ngIf="secondStep.get('creditos_html').hasError('required') && secondStep.controls['creditos_html'].dirty">
                                                    {{'generico.required' | translate}}
                                                </mat-error>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                            <div *ngIf="secondStep.controls['type'].value === 5" class="d-flex flex-column form-group default-dropzone mb-4">
                                <label>{{ 'dialogs.add-recurso.step2.recurso3.form.file' | translate }}</label>
                                <div class="d-flex flex-column flex-md-row align-items-center">
                                    <div (dropZone)="onDropFile($event, 'audio')" (click)="fileField.click()" class="w-100 default-dropzone__container d-flex align-items-center justify-content-center">
                                        <mat-icon *ngIf="!secondStep.get('file').value" class="custom-icon icon">cloud_upload</mat-icon>
                                        <mat-icon *ngIf="secondStep.get('file').value" class="custom-icon icon">cloud_done</mat-icon>
                                    </div>

                                    <div class="w-60 ms-4 p-0 d-flex flex-column default-dropzone__text">
                                        <p class="mb-0">{{'dialogs.adjuntar-fichero.upload-info' | translate}}<a [matMenuTriggerFor]="menu">{{secondStep.get('file').value ? 'cambia' : 'añade' }}</a>{{'dialogs.adjuntar-fichero.upload-info2' | translate}}</p>
                                        <input type="file" name="file" #fileField (change)="onDropFile($event.target.files[0], 'audio')" hidden>
                                        <mat-menu #menu="matMenu" class="default-dropdown">
                                            <button mat-menu-item (click)="fileField.click()"> <mat-icon class="material-icons-filled">portrait</mat-icon>Seleccionar fichero</button>
                                            <button [disabled]="!secondStep.get('file').value" mat-menu-item (click)="deleteFile()"> <mat-icon class="material-icons-filled">delete</mat-icon>Borrar fichero</button>
                                        </mat-menu>
                                        <p class="mb-0">{{'dialogs.adjuntar-fichero.ayuda' | translate}}</p>
                                    </div>
                                </div>
                                <mat-error class="danger-text text__small mt-2" *ngIf="error.audio">
                                    {{'generico.formulario.fichero-novalid' | translate}}
                                </mat-error>
                            </div>
                        </div>
                    </form>
                </ng-container>

            </mat-step>
        </mat-horizontal-stepper>
    </div>
    <div mat-dialog-actions class="justify-content-between">
        <button role="button" class="default-button default-button__primary" mat-dialog-close>{{ 'generico.cancelar' | translate }}</button>

        <div class="d-flex">
            <button role="button" class="default-button default-button__primary me-3" [disabled]="currentStep < 1" (click)="goBack()">{{ 'generico.anterior' | translate}}</button>
            <button role="button" class="default-button default-button__secondary me-3" *ngIf="currentStep < 1" (click)="goNext()">{{ 'generico.siguiente' | translate}}</button>
            <button role="button" class="default-button default-button__secondary" (click)="onClose()" *ngIf="currentStep >= 1" [disabled]="!secondStep.valid && selection.selected.length === 0 && !plantillaSelected">{{ 'generico.anadir' | translate }}</button>
        </div>
    </div>

</div>
