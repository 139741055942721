import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { LocalCacheService } from '../../../core/load-cache/load-cache.service';
import { Tema } from '../../../shared/models/tema/tema.model';
import { EnvService } from '../../env.service';

@Injectable({
  providedIn: 'root'
})
export class TemasDataService {

  constructor(private http: HttpClient, public cache: LocalCacheService, private envService: EnvService) { }

  public getTema(idTema: string): Observable<any> {
    // return this.http.get<any>(`${this.envService.apiUrl}/contenido/Tema/${idTema}`);
    // const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/contenido/Tema/${idTema}`);
    // return this.cache.observable(`TEMASDATASERVICE-GETTEMA-${idTema}`, requestObservable);
    return this.http.get<any>(`${this.envService.apiUrl}/contenido/Tema/${idTema}`);
  }

  public getModulo(idTema: string, idModulo: string, contextId: string): Observable<any> {
    let url = `${this.envService.apiUrl}/contenido/Modulo/Recurso/${idModulo}/Tema/${idTema}`;
    if (contextId) {
      url = url + `?contextId=${contextId}`;
    }
    return this.http.get<any>(url);
    // const requestObservable = this.http.get<any>(`${this.envService.apiUrl}/contenido/Modulo/Recurso/${idModulo}/Tema/${idTema}`);
    // return this.cache.observable(`TEMASDATASERVICE-GETMODULO-${idModulo}`, requestObservable);
  }

  public getModuloSeccion(idTema: string, idModulo: string): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/contenido/Modulo/Seccion/${idModulo}/Tema/${idTema}`);
  }

  public getOtrosTemas(idMateria): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/contenido/materias/${idMateria}/otros-temas/indice`);
    // return this.cache.observable('TEMASDATASERVICE-GETOTROSTEMAS', requestObservable);
  }

  public getTareasPorSeccion(idTema: string, idSeccion: string): Observable<any> {
    // return this.http.get<any>(`${this.envService.apiUrl}/contenido/tema/${idTema}/seccion/${idSeccion}/numero-tareas`);
    return of(0);
  }

  public createTema(tema): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/materia-tema/crear`, { ...tema }, { headers });
  }

  public deleteTema(data: any): Observable<any> {
    const httpOptions = {
      headers: {},
      body: { ...data }
    };
    return this.http.delete<any>(`${this.envService.apiUrl}/contenido/materia-tema/eliminar`, httpOptions);
  }

  public descargaProgramacionAula(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/Tema/programacion`, data);
  }

  public duplicarTema(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/materia-tema/duplicar`, data);
  }

  public moverTema(data): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/materia-tema/mover`, data);
  }

  public copiarTema(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/materia-tema/copiar`, data);
  }

  public crearEnlace(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/materia-tema/enlazar`, data);
  }

  public descargarIndicePdf(materiaId, userId, rol): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/contenido/materias/${materiaId}/indice-descargar`,
      { responseType: 'blob' as 'json' }
    );
  }

  public descargarContenidoPdf(temaId, materiaId): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/contenido/Tema/${temaId}/materia/${materiaId}/Pdf`,
      { responseType: 'blob' as 'json' }
    );

  }

  public descargarContenidoPdfResumen(temaId, materiaId): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/contenido/Tema/${temaId}/materia/${materiaId}/PdfResumen`,
      { responseType: 'blob' as 'json' }
    );
  }

  public desasignarContenido(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/Tema/desasignar`, data);
  }

  public updateTema(tema: Tema, materiaId: number): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/Tema`, { tema, materiaId });
  }

  public mostrarTema(data: any): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/materia-tema/mostrar`, { ...data });
  }

  public guiaDidactica(data): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/tema/guia-didactica`, data);
  }

  public copiarModulo(data): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/Modulo/copiar-en-otro-tema`, data);
  }

  public moverModulo(data): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/Modulo/mover-a-otro-tema`, data);
  }

  public getAnotaciones(): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/contenido/Anotaciones`);
  }

  public addAnotaciones(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/Anotaciones`, data);
  }

  public deleteAnotaciones(data): Observable<any> {
    const httpOptions = {
      headers: {},
      body: { ...data }
    };
    return this.http.delete<any>(`${this.envService.apiUrl}/contenido/Anotaciones`, httpOptions);
  }

  public getHighlights(temaId): Observable<any> {
    const headers = { noLoader: 'noLoader' };
    return this.http.get<any>(`${this.envService.apiUrl}/subrayado/tema/${temaId}`, { headers });
  }

  public addHighlights(temaId, moduloId, data): Observable<any> {
    const headers = { noLoader: 'noLoader' };
    return this.http.post<any>(`${this.envService.apiUrl}/subrayado/tema/${temaId}/modulo/${moduloId}`, data, { headers });
  }

  public deleteHighlights(temaId, moduloId,): Observable<any> {
    const headers = { noLoader: 'noLoader' };
    return this.http.delete<any>(`${this.envService.apiUrl}/subrayado/tema/${temaId}/modulo/${moduloId}`, { headers });
  }

  public asignarUnidad(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/materia-tema/asignar`, data);
  }

  public compartirTema(data): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/tema/compartir`, data);
  }

  public compartirCon(data): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/tema/compartirCon`, data);
  }

  public compartirModulo(data): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/Modulo/compartir`, data);
  }

  public crearModuloDesdePlantilla(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/Modulo/crear-desde-plantilla`, data);
  }

  public crearModuloRecursoEnlace(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/Modulo/recurso/enlace`, data);
  }

  public crearModuloRecursoDocumento(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/Modulo/recurso/documento`, data);
  }

  public crearModuloRecursoVideo(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/Modulo/recurso/video`, data);
  }

  public crearModuloRecursoImagen(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/Modulo/recurso/imagen`, data);
  }

  public crearModuloRecursoAudio(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/Modulo/recurso/audio`, data);
  }

  public crearModuloDocumento(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/modulo/documento`, data);
  }

  public crearModuloAudio(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/modulo/audio`, data);
  }

  public crearModuloVideo(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/Modulo/video`, data);
  }

  public crearModuloImagen(data): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/Modulo/imagen`, data);
  }

  public adjuntarFicheroModulo(idModulo, fichero): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/Modulo/${idModulo}/adjuntar`, fichero);
  }

  public getModuloDesdePlantilla(idModulo): Observable<any> {
    return this.http.get<any>(`${this.envService.apiUrl}/contenido/Modulo/${idModulo}`);
  }

  public duplicarModuloConEditorReferenciado(idModulo): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/Modulo/duplicar/${idModulo}`, null);
  }

  public duplicarModulo(idModulo): Observable<any> {
    return this.http.post<any>(`${this.envService.apiUrl}/contenido/Modulo/duplicar/${idModulo}`, null);
    // return of({ valor: 'vacio' });
  }

  public setTemaEnEdicion(idTema: string): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/Tema/${idTema}/SetTemaEnEdicionOn`, null);
  }

  public unsetTemaEnEdicion(idTema: string): Observable<any> {
    return this.http.put<any>(`${this.envService.apiUrl}/contenido/Tema/${idTema}/SetTemaEnEdicionOff`, null);
  }

  public syncUnsetTemaEnEdicion(idTema: string, jwt: string) {
    // Created a synchronous method to force call to endpoint when closing browser
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', `${this.envService.apiUrl}/contenido/Tema/${idTema}/SetTemaEnEdicionOff`, true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + jwt);
    xhr.send();
  }
}
