import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MatStepper } from '@angular/material/stepper';
import { GestionAlumnoManagementService } from '../../../../services/data/gestion-alumno/gestion-alumno-management.service';
import { TareaManagementService } from '../../../../services/data/tarea/tarea-management.service';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { Subject, forkJoin, timer } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { GenericComponent } from '../generic/generic.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'aula-planeta-reasignar-tarea',
  templateUrl: './reasignar-tarea.component.html',
  styleUrls: ['./reasignar-tarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class ReasignarTareaComponent implements OnInit, AfterViewInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('stepper') private stepperSection: MatStepper;

  thirdStep1: UntypedFormGroup;
  thirdStep2: UntypedFormGroup;
  currentStep = 1;

  minDate = new Date(Date.now());
  maxDate = new Date(2045, 0, 1);
  indexTab = 0;

  selectionDisponibles: SelectionModel<null>;
  selectionAsignados: SelectionModel<null>;

  alumnos: any[] = [];
  alumnosTemp: any[] = [];
  alumnosAsignados: any[] = [];
  enableCustomGroup = false;
  seleccionManualAlumnos = false;
  habilitarSeleccionManualAlumnos = true;

  grupos: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ReasignarTareaComponent>,
    private dialog: MatDialog,
    public translateService: TranslateService,
    public gestionAlumnoManagementService: GestionAlumnoManagementService,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    this.selectionDisponibles = new SelectionModel<null>(true, []);
    this.selectionAsignados = new SelectionModel<null>(true, []);
  }

  ngAfterViewInit() {
    const info = {
      event: 'popup_steps',
      popupSteps: {
        popupId: 'dialog-reasignar-tarea',
        popupName: 'popup_reasignar_tarea',
        popupStep: this.currentStep.toString(),
        pageId: this.data.event?.view?.location.hash
      }
    };
    this.gtagService.event(info);
  }

  ngOnInit() {

    this.thirdStep1 = this.fb.group({
      grupo: new UntypedFormControl('', Validators.required)
    });

    this.thirdStep2 = this.fb.group({
      alumnosAsignados: new UntypedFormControl([], Validators.required),
      curso: new UntypedFormControl([], Validators.required),
      nombreGrupo: new UntypedFormControl('')
    });

    this.gestionAlumnoManagementService.getMisClases();
    this.gestionAlumnoManagementService.getMisAlumnos();
    this.gestionAlumnoManagementService.misClases$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(clases => {
        this.grupos[0] = clases.map(el => ({
          nombre: `${el.curso.ordinal} ${el.curso.etapa.acronimo} ${el.grupo.id}`,
          clase: { curso: el.curso, grupo: el.grupo },
          alumnos: el.alumnos,
          tipoAsignacion: 'Clase'
        }));
      });
    this.gestionAlumnoManagementService.getMisGruposPersonalizados();
    this.gestionAlumnoManagementService.misGruposPersonalizados$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(gruposPersonalizados => {
        this.grupos[1] = gruposPersonalizados.map(el => ({
          id: el.id,
          nombre: el.nombre,
          alumnos: el.alumnos,
          tipoAsignacion: 'GrupoPersonalizado'
        }));
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClose() {
    this.dialogRef.close({
      alumnosAsignados: this.alumnosAsignados.map(alumno => alumno.id),
      tipoAsignacion: this.seleccionManualAlumnos ? 'SeleccionAlumnos' : this.thirdStep2.value.curso[0].tipoAsignacion,
      grupoPersonalizadoId: this.seleccionManualAlumnos ? this.thirdStep2.value.nombreGrupo : this.thirdStep2.value.curso[0].id,
      cursoId: !this.seleccionManualAlumnos ? this.thirdStep2.value.curso[0].clase.curso.id : null,
      grupoId: !this.seleccionManualAlumnos ? this.thirdStep2.value.curso[0].clase.grupo.id : null
    });
  }

  setAlumnosList(grupo) {
    let alumnos = [];
    if (!grupo.clase) {
      alumnos = grupo.alumnos;
      this.habilitarSeleccionManualAlumnos = false;
    } else {
      this.habilitarSeleccionManualAlumnos = true;
      alumnos = this.gestionAlumnoManagementService.getListaAlumnosEnUnaClase(grupo.clase, this.gestionAlumnoManagementService.misAlumnos);
    }
    this.alumnosTemp = (alumnos && alumnos.length > 0) ? alumnos : [];
    this.alumnos = this.alumnosTemp.filter(alumno => !this.alumnosAsignados.some(aluAsi => aluAsi.id === alumno.id));
    if (!this.seleccionManualAlumnos) {
      this.asignarTodos();
      this.alumnosAsignados = alumnos;
    } else {
      this.desasignarAlumnos();
      this.alumnosAsignados = [];
    }
    
  }



  setAlumnosListMulti() {
    
   
  
    this.alumnos = [];
    this.alumnosTemp = [];
    this.alumnosAsignados = [];
    this.habilitarSeleccionManualAlumnos = true;
  
    const gruposSeleccionados = this.thirdStep2.get('curso').value;
   
  
    const alumnosTotales = [];
  
    gruposSeleccionados.forEach(grupo => {
      let alumnosGrupo = [];
      if (!grupo.clase) {
        alumnosGrupo = grupo.alumnos;
       
      } else {
        // eslint-disable-next-line max-len
        alumnosGrupo = this.gestionAlumnoManagementService.getListaAlumnosEnUnaClase(grupo.clase, this.gestionAlumnoManagementService.misAlumnos);
        
      }
      alumnosTotales.push(...alumnosGrupo);
    });
  
   
  
    // Eliminar duplicados
    const alumnosUnicos = alumnosTotales.filter((alumno, index, self) =>
      index === self.findIndex((t) => (
        t.id === alumno.id
      ))
    );
  
 
  
    this.alumnos = alumnosUnicos.filter(alumno => !this.alumnosAsignados.some(aluAsi => aluAsi.id === alumno.id));
   
  
    if (!this.seleccionManualAlumnos) {
      this.asignarTodos();
      this.alumnosAsignados = alumnosUnicos;
    
    } else {
      this.desasignarAlumnos();
      this.alumnosAsignados = [];
      
    }
  
    const alumnosDuplicados = this.alumnosAsignados.filter((obj, index, arr) => arr.findIndex(t => t.id === obj.id) !== index);
  
   
  
    if (alumnosDuplicados.length > 0) {
      document.body.classList.add('cdk-global-scrollblock');
      const dialogRef = this.dialog.open(GenericComponent, {
        data: {
          titulo: this.translateService.instant('generico.atencion'),
          icon: 'info',
          contenido: this.translateService.instant('dialogs.asignar-tarea.step3.aumnios-repetidos', {
            alumnos: alumnosDuplicados.map(a => `${a.nombre} ${a.apellidoUno} ${a.apellidoDos}`).join(', \n')
          }),
          color: 'standard',
          close: true,
          actions: [
            {
              key: 'generico.cerrar',
              type: 'secondary',
              onClose: false,
            }
          ],
          event: event,
          action: 'eliminar'
        },
        restoreFocus: true
      });
  
      dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$),).subscribe(() => {
        document.body.classList.remove('cdk-global-scrollblock');
      });
    }
  }
  

  asignarAlumnos() {
    
    this.alumnosAsignados = this.alumnosAsignados.concat(this.selectionDisponibles.selected);
    

    this.thirdStep2.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.alumnos = this.alumnos.filter(alumno => !this.selectionDisponibles.selected.includes(alumno));
    this.selectionDisponibles.clear();
  }

  asignarTodos() {
    
    this.alumnos.forEach((row) => this.selectionDisponibles.select(row));
    this.asignarAlumnos();
  }

  desasignarAlumnos() {
    
    this.alumnos = this.alumnos.concat(this.alumnosTemp.filter(alumno => this.selectionAsignados.selected.includes(alumno)));

    this.alumnosAsignados = this.alumnosAsignados.filter(alumno => !this.selectionAsignados.selected.includes(alumno));
    this.thirdStep2.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.selectionAsignados.clear();
    
  }

  desasignarTodos() {
    
    this.alumnosAsignados.forEach((row) => this.selectionAsignados.select(row));
    this.desasignarAlumnos();
  }
}