// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const packageJson = require('../../../../package.json');

export const environment = {
  appName: 'Cosmos',
  envName: 'dev',
  production: false,
  test: false,
  api: 'https://intapi.aulaplaneta.com/api',
  rootContent: 'https://int.aulaplaneta.com',
  rootContentNoCDN: 'https://int.aulaplaneta.com',
  rootContentFind: 'https://find.aulaplaneta.com',
  rootContentRocket: 'https://introcket.aulaplaneta.com',
  // api: 'https://intapi.aulaplaneta.com/api',
  // rootContent: 'https://int.aulaplaneta.com',
  // rootContentNoCDN: 'https://int.aulaplaneta.com',
  // rootContentFind: 'https://find.aulaplaneta.com',
  // rootContentRocket: 'https://introcket.aulaplaneta.com',
  googleId: '926325069712-jotjgejnsjlgb1rcjrfolmd7kohdvtv5.apps.googleusercontent.com',
  googleSecret: '_-1RPoeQfuLpZBo-F4I1yyhN',
  googleIdToken: '504901875830-5arh4mshk3lidsbav5p1gh4cnn735cm2.apps.googleusercontent.com',
  googleSecretToken: '_-1RPoeQfuLpZBo-F4I1yyhN',
  googleAnalytics: 'GTM-PTD9CB2',
  bancoUri: 'https://prebanco.aulaplaneta.com',
  worldbookUri: 'https://worldbook.aulaplaneta.com',
  maticUri: 'https://api-qa.adaptemy.com/sso/aula/auth',
  teamsClientId: '04a0b9c0-d542-4e04-9af6-971faa57d8a4',
  teamsScope: 'api://int.aulaplaneta.com/04a0b9c0-d542-4e04-9af6-971faa57d8a4/access_as_user',
  i18nPrefix: '',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress'],
    eslint: packageJson.devDependencies['eslint']
  }
};
