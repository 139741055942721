<div id="dialog-mis-clases" role="form" class="standard d-flex flex-column">

    <h1 class="custom-text heading-6" mat-dialog-title>{{'dialogs.mis-clases.titulo' | translate}}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <div class="overflow-auto">
            <table *ngIf="data.cursos && data.cursos.length > 0" #table mat-table multiTemplateDataRows class="w-100 mb-5 default-table" [dataSource]="data.cursos">

                <ng-container matColumnDef="curso">
                    <th mat-header-cell *matHeaderCellDef class="custom-text font-weight-bold w-7 ps-0">{{'seguimiento-alumno.gestion-alumno.tabla.curso' | translate}}</th>
                    <td mat-cell class="font-weight-bold ps-0" *matCellDef="let element">{{element.ordinal}} {{element.etapa.acronimo}}</td>
                </ng-container>

                <ng-container *ngFor="let grupo of data.grupos" [matColumnDef]="grupo.id">
                    <th mat-header-cell *matHeaderCellDef class="custom-text font-weight-bold w-7"> {{ grupo.id }} </th>
                    <td mat-cell *matCellDef="let curso">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectClase(curso.id, grupo.id) : null " [checked]="isEstaUnaDeMisClases(curso.id,  grupo.id)"></mat-checkbox>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row class="base" *matRowDef="let element; columns: displayedColumns;"></tr>
            </table>
            <div class="no-results-base" *ngIf="data.cursos && data.cursos.length < 1">
                <mat-icon class="material-icons-round">how_to_reg</mat-icon>
                <div>
                    <span>{{ 'dialogs.mis-clases.sin-clases-asignadas' | translate }}</span>
                    <button class="ms-4 default-button default-button__primary" role="button" type="button">{{ 'dialogs.mis-clases.seleccionar-clases' | translate }}</button>
                </div>
            </div>
        </div>

    </div>

    <div mat-dialog-actions class="align-items-end mt-3">
        <button role="button" class="default-button default-button__primary me-3 cancelar" mat-dialog-close (click)="close(false)">{{ 'generico.cancelar' | translate }}</button>
        <button role="button" class="default-button default-button__secondary guardar-seleccion" (click)="close(true)" type="submit" [disabled]="!enableSaveButton" cdkFocusRegionEnd>{{ 'dialogs.mis-clases.guardar-seleccion' | translate }}</button>
    </div>

</div>
