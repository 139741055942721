<div id="dialog-reasignar-tarea" class="standard d-flex flex-column">
    <h1 mat-dialog-title class="custom-text font-weight-bold">{{ 'dialogs.reasignar-tarea.titulo' | translate }}</h1>
    <div mat-dialog-content class="mat-dialog-content">
        <mat-horizontal-stepper [linear]="true" #stepper class="default-stepper" selectedIndex="2">
            <mat-step aria-disabled="true" [completed]="true" [editable]="false" label="{{ 'dialogs.repetir-tarea.step1' | translate}}"></mat-step>

            <mat-step aria-disabled="true" [completed]="true" [editable]="false" label="{{ 'dialogs.repetir-tarea.step2' | translate}}"></mat-step>

            <mat-step [stepControl]="thirdStep1 && thirdStep2" label="{{ 'dialogs.asignar-tarea.step3' | translate}}">
                <h2 class="custom-text my-4">{{ 'dialogs.asignar-tarea.step3.intro' | translate }}</h2>

                <div class="overflow-auto">
                    <!-- <form [formGroup]="thirdStep"> -->
                    <mat-tab-group class="standard">
                        <!-- <mat-tab label="{{ 'dialogs.asignar-tarea.step3.select-grupo' | translate}}">
                            <form [formGroup]="thirdStep1">
                                <fieldset class="w-100 d-flex flex-column my-4">
                                    <div class="form-group w-35">
                                        <label class="default-text">{{ 'dialogs.asignar-tarea.step3.mis-grupos' | translate }}</label>
                                        <mat-select cdkFocusInitial class="default-select default-select__blue" [placeholder]="'dialogs.asignar-tarea.step3.mis-grupos' | translate" formControlName="grupo" panelClass="cdkSelect">
                                            <mat-optgroup *ngFor="let grupo of grupos" class="default-group-options">
                                                <mat-option class="default-option" *ngFor="let item of grupo" [value]="item.id">
                                                    {{item.id}}
                                                </mat-option>
                                            </mat-optgroup>
                                        </mat-select>
                                    </div>
                                </fieldset>
                            </form>
                        </mat-tab> -->

                      <mat-tab label="{{ 'dialogs.asignar-tarea.step3.select-alumnos' | translate}}">
                        <form [formGroup]="thirdStep2">
                          <div class="w-100 d-flex flex-column flex-md-row justify-content-between my-4">
                            <div class="d-flex flex-column w-35">
                              <div class="form-group">
                                <label for="grupo" class="default-text">{{ 'dialogs.asignar-tarea.step3.grupo' | translate }}</label>
                                <mat-select
                                  multiple
                                  id="grupo"
                                  cdkFocusInitial class="default-select default-select__blue" [placeholder]="'dialogs.asignar-tarea.step3.grupo' | translate" formControlName="curso" panelClass="cdkSelect">
                                  <mat-optgroup *ngFor="let grupo of grupos" class="default-group-options">
                                    <mat-option class="default-option" *ngFor="let _grupo of grupo" [value]="_grupo" (click)="setAlumnosListMulti()">
                                      {{_grupo.nombre}}
                                    </mat-option>
                                  </mat-optgroup>
                                </mat-select>
                              </div>
                              <div class="form-group mb-3">
                                <mat-slide-toggle class="grey-text" *ngIf="habilitarSeleccionManualAlumnos" disableRipple="true" (change)="seleccionManualAlumnos = !seleccionManualAlumnos; enableCustomGroup = false; desasignarTodos(); setAlumnosList(thirdStep2.get('curso').value)">{{ 'dialogs.asignar-tarea.step3.seleccion-manual' | translate }}</mat-slide-toggle>
                              </div>
                              <div class="form-group" *ngIf="seleccionManualAlumnos">
                                <mat-slide-toggle class="grey-text" disableRipple="true" (change)="enableCustomGroup = !enableCustomGroup; ">{{ 'dialogs.asignar-tarea.step3.create-grupo' | translate }}</mat-slide-toggle>
                              </div>
                              <div *ngIf="enableCustomGroup && seleccionManualAlumnos" class="form-group default-input">
                                <label for="nombreGrupo" class="default-text">{{ 'dialogs.asignar-tarea.step3.nombre-grupo-personalizado' | translate }}</label>
                                <input id="nombreGrupo" type="text" class="form-control" formControlName="nombreGrupo" [placeholder]="('dialogs.asignar-tarea.step3.nombre-grupo-personalizado' | translate)">
                              </div>
                            </div>

                            <div *ngIf="seleccionManualAlumnos" class="alumnos-container w-60 d-flex flex-column flex-md-row mb-4">
                              <fieldset class="w-100 alumnos-container__list">
                                <legend class="w-100 pb-2 mb-0 default-text">{{ 'mi-centro.gestion-contrasenas.alumnos-disponibles' | translate }}</legend>
                                <div>
                                  <div *ngFor="let item of alumnos" class="my-3">
                                    <mat-checkbox class="ms-3" (click)="$event.stopPropagation()" (change)="$event ? selectionDisponibles.toggle(item) : null " [checked]="selectionDisponibles.isSelected(item)" [aria-label]="item.nombreCompleto">
                                      {{item.nombre}} {{item.apellidoUno}} {{item.apellidoDos}}
                                    </mat-checkbox>
                                  </div>
                                </div>
                              </fieldset>
                              <div role="region" [attr.aria-label]="('dialogs.asignar-tarea.step3.acciones-alumnos-seleccionados' | translate)" class="alumnos-container__actions d-flex flex-row flex-md-column justify-content-center align-content-center px-4">
                                <button mat-icon-button class="custom-text" (click)="asignarAlumnos()" attr.aria-label="{{'mi-centro.gestion-curso.asignar.alumnos' | translate}}">
                                  <mat-icon>chevron_right</mat-icon>
                                </button>
                                <button mat-icon-button class="custom-text" (click)="asignarTodos()" attr.aria-label="{{'mi-centro.gestion-curso.asignar.todos' | translate}}">
                                  <mat-icon>last_page</mat-icon>
                                </button>
                                <button mat-icon-button class="custom-text" (click)="desasignarTodos()" attr.aria-label="{{'mi-centro.gestion-curso.desasignar.todos' | translate}}">
                                  <mat-icon>first_page</mat-icon>
                                </button>
                                <button mat-icon-button class="custom-text" (click)="desasignarAlumnos()" attr.aria-label="{{'mi-centro.gestion-curso.desasignar.alumnos' | translate}}">
                                  <mat-icon>chevron_left</mat-icon>
                                </button>
                              </div>
                              <fieldset class="w-100 alumnos-container__list">
                                <legend class="w-100 pb-2 mb-0 default-text">{{ 'mi-centro.gestion-contrasenas.alumnos-seleccionados' | translate }}</legend>
                                <div>
                                  <div *ngFor="let item of alumnosAsignados" class="my-3">
                                    <mat-checkbox class="ms-3" (click)="$event.stopPropagation()" (change)="$event ? selectionAsignados.toggle(item) : null " [checked]="selectionAsignados.isSelected(item)" [aria-label]="item.nombreCompleto">
                                      {{item.nombre}} {{item.apellidoUno}} {{item.apellidoDos}}
                                    </mat-checkbox>
                                  </div>
                                </div>
                              </fieldset>
                            </div>

                          </div>
                        </form>
                      </mat-tab>
                    </mat-tab-group>

                    <!-- </form> -->
                </div>

            </mat-step>
        </mat-horizontal-stepper>
    </div>
    <div mat-dialog-actions class="justify-content-between">
        <button role="button" class="default-button default-button__primary" mat-dialog-close>{{ 'dialogs.create-theme.cancel' | translate }}</button>

        <div class="d-flex">
            <button role="button" class="default-button default-button__secondary" (click)="onClose()" [disabled]="!thirdStep2.get('curso').value">{{ 'generico.guardar' | translate }}</button>
        </div>
    </div>
