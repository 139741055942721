
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  OnInit
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModulosOrganizacion } from '../models/tema/modulo.model';

@Component({
  selector: 'aula-planeta-player',
  templateUrl: 'aula-planeta-player.html',
  styleUrls: ['aula-planeta-player.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() sources: SSVideoSource[] = [];
  @Input() config!: SSPlayerConfig;
  @Input() modulo: ModulosOrganizacion;

  @ViewChild('videoWrapper') videoWrapper!: ElementRef;
  @ViewChild('videoOverlay') videoOverlay!: ElementRef;
  @ViewChild('video') videoRef!: ElementRef;
  @ViewChild('audioSubtitles') audioSubtitles!: ElementRef;

  selectedVideoSource!: SSVideoSource;
  selectedVideoSourceIndex = 0;
  noData: Observable<any>;

  video?: HTMLVideoElement;
  showIcon = false;
  youtube = false;
  subtitles = '';
  formatSubtitles = 'hola';
  isPlaying = false;
  isInFullScreenMode = false;
  volume = 100;
  currentTime = 0;
  duration = 0;
  seekBarPercentage = 0;
  bufferPercentage = 0;
  overlayTimeoutInstance: any;
  timeBeforeHide = 3000; // 3 seconds;
  sliderVol = 'cerrado';
  isTranscripcionOpen = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // let's give initial values to selectedVideoSource to prevent any null or undefined related errors
    this.selectedVideoSource = {
      title: '',
      source: '',
      poster: '',
      initialTime: 0,
    };
  }

  ngAfterViewInit(): void {
    this.selectedVideoSource = this.sources[this.selectedVideoSourceIndex];
    this.youtube = this.sources[this.selectedVideoSourceIndex].youtube;
    // if (
    //   !this.selectedVideoSource.poster
    //     || (this.selectedVideoSource.poster && this.youtube)
    //     && !this.isPlaying && this.currentTime === 0) {
    this.showIcon = true;
    // }

    this.video = this.videoRef?.nativeElement;
    this.subtitles = this.sources[this.selectedVideoSourceIndex].subtitles;
    if (this.subtitles) {
      this.formatSubtitles = 'pedo';
      if (this.config.onlyAudio) {
        this.formatSubtitles = 'repedo';
        const value = this;
        this.formatAudioSubtitles(this.video, function (data) {
          // console.log(data);
          // console.log(value.formatSubtitles);
          value.formatSubtitles = decodeURIComponent(escape(data));
        });
      }
      else {
        this.formatVideoSubtitles(this.video);
      }
    }

    if (!this.youtube) {
      this.clearOverlayTimeout();
    }

    // If everything has loaded, we can start to detect changes
    this.changeDetectorRef.detectChanges();

    document.addEventListener('webkitfullscreenchange', () => {
      this.isInFullScreenMode = (document as any).webkitIsFullScreen;
    }, false);

    document.addEventListener('keydown', (event) => {
      if (event.key === ' ' && this.isInFullScreenMode) {
        this.togglePlayingState(false);
      }
    });
    this.volume = this.config.mute ? 0 : 100;

  }

  auxFunc(data): void {
    this.formatSubtitles = data;
  }

  formatAudioSubtitles(el, callback): void {
    el.addEventListener('canplay', () => {
      const tracks = el.getElementsByTagName('track');
      const subtitle = {
        track: null,
        data:
        {
          track: {}
        },
        load: function (track) {
          subtitle.track = track;
          if (subtitle.isSrt(subtitle.track.src)) {

            return new Promise(function (resolve, reject) {
              const xhr = new XMLHttpRequest();
              xhr.open('GET', subtitle.track.src);
              xhr.onload = function () {
                if (xhr.status >= 200 && xhr.status < 300) {
                  resolve(xhr.response);
                  callback(subtitle.convert(xhr.response));
                } else {
                  reject({
                    status: xhr.status,
                    statusText: xhr.statusText
                  });
                }
              };
              xhr.onerror = function () {
                reject({
                  status: xhr.status,
                  statusText: xhr.statusText
                });
              };
              xhr.send();
            });
          }
        },
        convert: function (content) {
          let result = '';
          const promise = new Promise(
            function (resolve, reject) {
              const temp = content.split(/\n\s*\n/g);
              for (let i = 0; i < temp.length; i++) {
                const arr = temp[i].split('\n');
                result += arr.slice(2, arr.length).join();
              };
            }
          );
          return result;
        },
        isSrt: function (filename) {
          return filename.split('.').pop().toLowerCase() === 'srt' ? true : false;
        },
        isVTT: function (filename) {
          return filename.split('.').pop().toLowerCase() === 'vtt' ? true : false;
        }
      };


      for (let i = 0; i < tracks.length; i++) {
        subtitle.load(tracks[i]);
      }
    });
  }

  // SOURCE: https://codepen.io/richardmauritz/pen/vbPORR
  formatVideoSubtitles(el): void {
    el.addEventListener('canplay', () => {
      const tracks = el.getElementsByTagName('track');
      const subtitle = {
        track: null,
        data:
        {
          track: {}
        },
        load: function (track) {
          subtitle.track = track;

          if (subtitle.isSrt(subtitle.track.src)) {
            const client = new XMLHttpRequest();
            client.open('GET', subtitle.track.src);
            client.onreadystatechange = function () {
              subtitle.convert(client.responseText).then(
                function (file) {
                  subtitle.track.src = file;
                }
              );
            };
            client.send();
          }
        },
        convert: function (content) {
          const promise = new Promise(
            function (resolve, reject) {
              content = content.replace(/(\d+:\d+:\d+)+,(\d+)/g, '$1.$2');
              content = 'WEBVTT - Generated using SRT2VTT\r\n\r\n' + content;

              const blob = new Blob([content], { type: 'text/vtt' });
              const file = window.URL.createObjectURL(blob);

              resolve(file);
            }
          );
          return promise;
        },
        isSrt: function (filename) {
          return filename.split('.').pop().toLowerCase() === 'srt' ? true : false;
        },
        isVTT: function (filename) {
          return filename.split('.').pop().toLowerCase() === 'vtt' ? true : false;
        }
      };

      for (let i = 0; i < tracks.length; i++) {
        subtitle.load(tracks[i]);
      }

    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sources) {
      if (this.selectedVideoSourceIndex >= this.sources.length - 1) {
        this.selectedVideoSourceIndex = 0;
        this.selectedVideoSource = this.sources[this.selectedVideoSourceIndex];
      }
    }
  }

  play(index?: number): void {
    if (typeof index !== 'undefined' && this.sources.length > index) {
      this.stop();
      this.selectedVideoSourceIndex = index;
      this.selectedVideoSource = this.sources[this.selectedVideoSourceIndex];
    }

    this.video?.play();
    this.isPlaying = true;
    this.clearOverlayTimeout(true);

  }

  pause(): void {
    this.video?.pause();
    this.isPlaying = false;

    this.clearOverlayTimeout();
  }

  stop(): void {
    this.pause();
    this.skip((!this.selectedVideoSource?.poster && this.selectedVideoSource?.initialTime) || 0);
  }

  next(): void {
    this.pause();

    if (this.selectedVideoSourceIndex < this.sources.length - 1) {
      this.selectedVideoSourceIndex++;
    }

    this.selectedVideoSource = this.sources[this.selectedVideoSourceIndex];
  }

  prev(): void {
    this.pause();

    if (this.selectedVideoSourceIndex > 0) {
      this.selectedVideoSourceIndex--;
    }

    this.selectedVideoSource = this.sources[this.selectedVideoSourceIndex];
  }

  togglePlayingState(ev: any): void {
    if (ev && this.modulo.comunes.tipoModulo.valor !== 'audio') {
      let videoIcon;
      if (ev.srcElement.classList.contains('video-overlay-buttons')) {
        videoIcon = ev.srcElement.querySelector('#video-icon-transition');
      } else {
        const overlay = ev.srcElement.closest('.video-overlay');
        videoIcon = overlay.querySelector('#video-icon-transition');
      }

      videoIcon.classList.remove('d-none');
      videoIcon.classList.add('bounce-in-out');

      setTimeout(() => {
        videoIcon.classList.remove('bounce-in-out');
        videoIcon.classList.add('d-none');
      }, 600);
    }

    if (this.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  }

  toggleVolumeState(): void {
    this.volume = this.volume > 0 ? 0 : 100;

    if (this.video) {
      this.video.volume = this.volume / 100;
    }
  }

  onVideoLoaded(): void {
    // Show loading animation.
    const playPromise = this.video?.play();
    this.onProgress();
    if (playPromise !== undefined) {
      playPromise.then(_ => {
        // Automatic playback started!
        // Show playing UI.
        if (!this.config.autoplay && !this.isPlaying) {
          this.stop();
        } else {
          this.play();
        }
      })
        .catch(error => {
          // Auto-play was prevented
          // Show paused UI.
        });
    }

    if (this.video) {
      this.video.volume = this.volume ? 1 : 0;
    }
  }

  onVideoPlaying(): void {
    //
  }

  onVideoTimeUpdated(): void {
    this.currentTime = this.video?.currentTime || 0;
    this.duration = this.video?.duration || 0;
    this.seekBarPercentage = Math.floor((this.currentTime / this.duration) * 100);

    this.onProgress();
  }

  onProgress(): void {
    if (this.duration > 0) {
      for (let i = 0; i < this.video.buffered.length; i++) {

        if (this.video.buffered.start(this.video.buffered.length - 1 - i) <= this.video.currentTime) {
          // this.bufferPercentage = Math.round((this.video.buffered.end(this.video.buffered.length - 1) / this.duration) * 100);
          this.bufferPercentage = Math.floor((this.video.buffered.end(this.video.buffered.length - 1 - i) * 100) / this.duration);
          break;
        }
      }
    }
  }

  onVideoEnded(): void {
    switch (this.config.loop) {

      case SSLoopType.Once:
        this.play();
        break;

      case SSLoopType.All:
        if (this.sources.length - 1 > this.selectedVideoSourceIndex) {
          this.next();
        } else {
          this.selectedVideoSourceIndex = 0;
          this.selectedVideoSource = this.sources[this.selectedVideoSourceIndex];
        }
        setTimeout(() => {
          this.play();
        }, 1000);
        break;

      case SSLoopType.None:
        this.stop();
        break;
    }
  }

  // onVolumeChange(ev: MouseEvent): void {
  //     const volumeBarHeight = (ev.target as any).offsetHeight; // Computed width of the volume bar element
  //     const ratio = (volumeBarHeight - ev.offsetY) / volumeBarHeight; // it will change between 0 and 1

  //     if (this.video) {
  //         this.video.volume = ratio;
  //         this.volume = ratio * 100;
  //     }
  // }

  cambiaVol(event: any): void {
    this.video.volume = event.value;
  }

  // onVolumeChanging(ev: MouseEvent): void {
  //     if (ev.buttons > 0) {
  //         this.onVolumeChange(ev);
  //     }
  // }

  abreVolumen(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.sliderVol === 'cerrado' ? this.sliderVol = 'abierto' : this.sliderVol = 'cerrado';
    
  }

  onSeek(ev: MouseEvent): void {
    const seekBarWidth = (ev.target as any).offsetWidth; // Computed width of the seek bar element
    const ratio = ev.offsetX / seekBarWidth; // it will change between 0 and 1
    const computedPosition = this.duration * ratio;

    this.skip(computedPosition);
  }

  onSeeking(ev: MouseEvent): void {
    if (ev.buttons > 0) {
      this.onSeek(ev);
    }
  }

  skip(value: number): void {
    if (this.video) {
      this.video.currentTime = value;
    }
  }

  clearOverlayTimeout(reset?: boolean): void {
    if (this.videoOverlay) {
      this.videoOverlay.nativeElement.style.opacity = 1;
      this.videoOverlay.nativeElement.style.cursor = 'auto';
    }

    if (this.overlayTimeoutInstance) {
      clearTimeout(this.overlayTimeoutInstance);
    }

    if (reset && this.isPlaying) {
      this.overlayTimeoutInstance = setTimeout(() => {
        this.videoOverlay.nativeElement.style.opacity = 0;
        this.videoOverlay.nativeElement.style.cursor = 'none';
      }, this.timeBeforeHide);
    }
  }

  toggleFullscreen(): void {
    const wrapper = this.videoWrapper.nativeElement;

    // for more information: https://developer.mozilla.org/en-US/docs/Web/API/Document/fullscreen
    if (!this.isInFullScreenMode) {
      if (wrapper.webkitRequestFullScreen) {
        wrapper.webkitRequestFullScreen();
      }
    } else {
      if ((document as any).webkitCancelFullScreen) {
        (document as any).webkitCancelFullScreen();
      }
    }
  }

  ToggleTranscripcionAulaPlaneta() {
    if (this.config.onlyAudio) {
      // if (this.isTranscripcionOpen) {
      //     let transcripcion = (document.querySelector('.transcripcion') as HTMLElement);
      //     let closeIcon = (document.querySelector('.close-icon') as HTMLElement);
      //     transcripcion.style.color = 'transparent';
      //     closeIcon.style.display = 'none';
      // }
      this.isTranscripcionOpen = !this.isTranscripcionOpen;
    }
    else {

      const tracks = this.video.textTracks;
      const track = tracks[0];

      if (track.mode === 'showing') {
        track.mode = 'disabled';
      }
      else {
        track.mode = 'showing';
      }

    }
  }

  handleStartVideo() {
    if (!this.youtube) {
      this.togglePlayingState(false);
    }

    this.showIcon = !this.showIcon;
  }
}

export interface SSPlayerConfig {
  autoplay?: boolean;
  mute?: boolean;
  loop?: SSLoopType;
  trackUser?: boolean;
  onlyAudio?: boolean;
  component?: number;
}

export enum SSLoopType {
  None,
  Once,
  All
}

export interface SSVideoSource {
  title: string; // Title of the video
  source: string; // Local or remote path of the video
  poster?: string; // Local or remote path of the poster image
  subtitles?: string; // Local or remote path of the subtitles file
  initialTime?: number; // Time in seconds, it will be useful when no poster image available for the video otherwise it will not work,
  youtube?: boolean;
}
