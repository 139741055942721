<div [class]="'theme-wrapper ' + (settingsService.settings$  | async | settings: 'theme') + ' ' + changeBg(router.url)">

    <div overflow class="wrapper" id="wrapper">

        <ng-template [hasRole]="['Profesor']">
            <aula-planeta-edition-header></aula-planeta-edition-header>
        </ng-template>

        <header *ngIf="((!(ltiManagementService.moodle$ | async) && !(microsoftTeamsService.context$ | async))
          || ((microsoftTeamsService.context$ | async) && (microsoftTeamsService.context$ | async).page?.id !== 'Tab' ))
          && !(editionModeService.enable$ | async)" class="toolbar h-100 d-flex flex-column position-fixed justify-content-between align-items-center" (window.scroll)="eventoScroll($event)">

            <a href="#main" class="skip">{{ 'generico.skip-main-content' | translate }}</a>

            <ng-container *ngIf="!smartlinkManagementService.smartlink; else logo">
                <a routerLink="/" class="main-logo">
                    <mat-icon *ngIf="!(ltiManagementService.moodle$ | async)" id="logo-cosmos" svgIcon="logo_cosmos"></mat-icon>
                    <img *ngIf="ltiManagementService.moodle$ | async" id="logo-cosmos" src="assets/icons/AulaPlaneta-Simbolo_blanco.png" alt="Logo aulaplaneta" />
                </a>
            </ng-container>

            <ng-template #logo>
              <mat-icon *ngIf="!(ltiManagementService.moodle$ | async)" id="logo-cosmos" svgIcon="logo_cosmos"></mat-icon>
              <img *ngIf="ltiManagementService.moodle$ | async" id="logo-cosmos" src="assets/icons/AulaPlaneta-Simbolo_blanco.png" alt="Logo aulaplaneta" />
            </ng-template>


            <!-- <mat-icon id="producto-beta" svgIcon="rocket_beta"></mat-icon> -->


            <nav *ngIf="!isEvaluacionMicrosoft() &&
                (!(editionModeService.enable$ | async) &&
                !(ltiManagementService.moodle$ | async)  &&
                !smartlinkManagementService.smartlink &&
                !temasManagementService.fullscreen &&
                (!(microsoftTeamsService.context$ | async) ) ||
                ((microsoftTeamsService.context$ | async) &&
                (microsoftTeamsService.context$ | async).page?.id !== 'Tab'))" class="menu-column d-flex flex-column align-items-center">

                <ul class="list-unstyled primaryMenu">
                    <li *ngFor="let item of navigationSideMenu">
                        <ng-template [hasPermissions]="[item.funcionalidad]">
                            <a mat-list-item matTooltipPosition="below" [matTooltipClass]="'tooltipLeft'" [matTooltip]="item.label | translate" [attr.aria-label]="item.label | translate" [routerLink]="[item.link]" [routerLinkActive]="" [ngClass]="rla3.isActive?'active':''" #rla3="routerLinkActive"
                                disableRipple="true" (click)="gtagInfo(item, $event)" class="d-flex flex-column align-items-center">

                                <span>
                                    <mat-icon [ngClass]="{'icon-flipped': item.reverse}" aria-hidden="false"
                                        [attr.aria-label]="item.label | translate">{{item.icon}}</mat-icon>
                                </span>
                                <span>{{ item.label | translate }}</span>
                            </a>
                        </ng-template>
                    </li>
                </ul>

                <ul class="list-unstyled secondaryMenu">
                    <li *ngFor="let item of otherSideMenu">
                        <ng-template [hasPermissions]="[item.funcionalidad]">
                            <a tabindex="0" mat-list-item matTooltipPosition="below" [matTooltipClass]="'tooltipLeft'" [matTooltip]="item.label | translate" [href]="item.link" [target]="[item.newTab ? '_blank' : '_top']" class="d-flex flex-column align-items-center">
                                <mat-icon [svgIcon]="item.funcionalidad === 'BancoContenidos' ? 'icono_discovery' : null" [ngClass]="{'icon-flipped': item.reverse}" aria-hidden="false" (click)="route(item.link, item.newTab)" [attr.aria-label]="item.label | translate">{{item.icon}}</mat-icon>
                                {{ item.label | translate }}
                            </a>

                        </ng-template>
                    </li>
                </ul>
            </nav>

            <div *ngIf="!isEvaluacionMicrosoft() &&
            (!(editionModeService.enable$ | async) &&
            !(ltiManagementService.moodle$ | async)  &&
            !smartlinkManagementService.smartlink &&
            !temasManagementService.fullscreen &&
            (!(microsoftTeamsService.context$ | async) ) ||
            ((microsoftTeamsService.context$ | async) &&
            (microsoftTeamsService.context$ | async).page?.id !== 'Tab'))" mat-button class="d-flex justify-content-center align-items-center" id="toolbarUserMenu" role="button" [attr.aria-label]="'header.usuario.menu' | translate" (click)='!sideconf.open ? toggleSidenav(true, $event) : "toggleSidenav(false, $event)"'>
                <div>


                    <ng-container *ngIf="miCentroManagementService.logo$ | async as logo">
                        <div *ngIf="logo.logoVisible" (click)="gtagUserMenu(null, $event)" class="avatar d-flex align-items-center justify-content-center flex-column">

                            <img sanitize-img type="avatar" [absolute]="true" [src]="logo?.logo" alt="" loading="eager" />
                            <span class="d-none" aria-hidden="true">
                            <!-- {{user?.persona?.nombre?.charAt(0)}}{{user?.persona?.apellidoUno?.charAt(0)}} -->
                            <mat-icon>school</mat-icon>
                        </span>
                        </div>
                    </ng-container>

                    <div class="avatar d-flex align-items-center justify-content-center flex-column  mt-4" (click)="gtagUserMenu(null, $event)">


                        <img sanitize-img type="avatar" [absolute]="true" [src]="user?.fotoPerfil" alt="" loading="eager" />
                        <span class="d-none" aria-hidden="true">
                            {{user?.persona?.nombre?.charAt(0)}}{{user?.persona?.apellidoUno?.charAt(0)}}
                        </span>
                        <span class="mi_perfil_title mb-2"> {{teacherAvatarMenu[0].label | translate}}</span>
                    </div>



                </div>


                <!--
                <ng-template #iniciales>
                  <div class="avatar">
                    <span>
                      {{user?.persona.nombre.charAt(0)}}{{user?.persona.apellidoUno.charAt(0)}}
                    </span>
                  </div>
                </ng-template>
                -->

            </div>
        </header>

        <mat-sidenav #sidenav aria-modal="true" role="dialog" [attr.aria-label]="'menu.title' | translate" mode="over" autoFocus="false" disableClose (keydown.escape)="closeSidenav(sidenav)" (clickOutside)="closeSidenav(sidenav)" [excludes]="['menu-button']">
            <nav class="d-flex flex-column justify-content-between h-100">
                <div>
                    <div class="logo d-flex align-items-center justify-content-end">
                        <button type="button" class="btn btn-link btn-close" aria-label="Close" (click)="closeSidenav(sidenav)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>

                    <mat-nav-list>
                        <ul class="list-unstyled">
                            <li>
                                <a mat-list-item routerLink="mi-perfil" (click)="gtagUserMenu('menu.profile', $event);closeSidenav(sidenav)">
                                    <mat-icon [matTooltip]="'menu.profile' | translate">account_box</mat-icon>
                                    <span>{{ 'menu.profile' | translate }}</span>
                                </a>
                            </li>

                            <ng-template [hasRole]="['AdministradorCentro', 'Profesor']">
                                <li>
                                    <a mat-list-item routerLink="inicio/gestion-del-alumno" (click)="gtagUserMenu('menu.student-management', $event); closeSidenav(sidenav) ">
                                        <mat-icon [matTooltip]="'menu.student-management' | translate">people</mat-icon>
                                        <span>{{ 'menu.student-management' | translate }}</span>
                                    </a>
                                </li>
                            </ng-template>
                            <ng-template [hasRole]="['AdministradorCentro']">
                                <li>
                                    <a mat-list-item routerLink="mi-centro/alumnos/listado-alumnos" (click)="gtagUserMenu('menu.school-settings', $event); closeSidenav(sidenav)">
                                        <mat-icon [matTooltip]="'menu.school-settings' | translate">settings</mat-icon>
                                        <span>{{ 'menu.school-settings' | translate }}</span>
                                    </a>
                                </li>
                            </ng-template>
                            <li>
                                <a mat-list-item routerLink="mi-perfil" (click)="onLogoutClick(); gtagUserMenu('menu.logout', $event); closeSidenav(sidenav)">
                                    <mat-icon [matTooltip]="'menu.logout' | translate">logout</mat-icon>
                                    <span>{{ 'menu.logout' | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </mat-nav-list>
                </div>

                <div class="footer">
                    <img src="assets/logo01.svg" alt="{{'login.logo' | translate}}" class="mb-3" />
                    <mat-nav-list class="d-flex flex-column">

                        <ul class="list-unstyled focusable">
                            <li><a href="/textos-legales/condiciones-uso">{{ 'footer.conditions' | translate
                                    }}</a></li>
                            <!-- <li><a href="/textos-legales/politica-privacidad">{{ 'footer.privacity' | translate }}</a></li> -->
                            <li><a href="/textos-legales/politica-cookies">{{ 'footer.cookies' | translate
                                    }}</a></li>
                            <!-- <li><a href="https://www.planeta.es/es/oficina-de-buenas-practicas" target="_blank">{{'generico.practicas' | translate}}</a></li> -->
                        </ul>

                    </mat-nav-list>
                </div>
            </nav>
        </mat-sidenav>

        <div class="content" (scroll)="eventoScroll($event)" [ngClass]="{
        'mt-0 content-moodle-legacy': ((ltiManagementService.moodle$ | async) && !(editionModeService.enable$ | async)),
        'm-0': smartlinkManagementService.smartlink && temasManagementService.fullscreen,
        'super-fullscreen': (temasManagementService.fullscreen || (editionModeService.enable$ | async))}"
        [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig.data && o.activatedRoute.routeConfig.data.title">

            <!--mat-toolbar *ngIf="!temasManagementService.fullscreen && !smartlinkManagementService.smartlink"
              color="primary"
              class="d-flex justify-content-between align-items-center">

                <ng-container>
                    <div class="default-breadcrumb d-flex" id="breadcrumbs"
                        *ngIf="!(ltiManagementService.moodle$ | async)">
                        <div *ngFor="let breadcrumb of breadcrumbService.breadcrumbs$ | async"
                            [ngClass]="breadcrumb.color">
                            <span class="custom-text"
                                [ngClass]="breadcrumb.link ? 'cursor-pointer fade-in' : 'fade-in' "
                                (click)="breadcrumbService.navigateLink(breadcrumb.link); breadcrumbService.breadCrumbClickAction(breadcrumb.link)"
                                [innerHtml]="breadcrumb.value | safe:'html'"></span>
                        </div>
                    </div>
                </ng-container>

                <div *ngIf="!(ltiManagementService.moodle$ | async)" class="d-flex align-items-center">
                    <div *ngIf="(miCentroManagementService.logo$ | async) as logo" class="logo-container">
                        <img *ngIf="logo.logo && logo.logoVisible" [src]="logo.logo" [absolute]="true" sanitize-img
                            alt="{{'header.logo.centro' | translate}}" />
                    </div>
                </div>
            </mat-toolbar-->

            <mat-sidenav-container role="main" [hasBackdrop]="true" [ngClass]="{
              'open-tasklist': (taskListService.open$ | async),
              'mat-sidenav-width': !smartlinkManagementService.smartlink && !temasManagementService.fullscreen || smartlinkManagementService.smartlink,
              'mat-sidenav-full': temasManagementService.fullscreen || ((microsoftTeamsService.context$ | async) && (microsoftTeamsService.context$ | async).page?.id === 'Tab'),
              'mat-sidenav-lti': (ltiManagementService.moodle$ | async)
            }">
                <router-outlet #o="outlet"></router-outlet>
            </mat-sidenav-container>

            <ng-template [hasRole]="['Alumno']">
                <ng-container *ngIf="((!(ltiManagementService.moodle$ | async) && !(microsoftTeamsService.context$ | async) )
                  || ((microsoftTeamsService.context$ | async) && (microsoftTeamsService.context$ | async).page.id !== 'Tab')) &&
                  !temasManagementService.fullscreen">

                    <div *ngIf="tareaManagementService.notificaciones$ | async as notificaciones" class="task-list menu-column position-fixed d-flex flex-column align-items-center pt-4" [ngClass]="(taskListService.open$ | async) ? 'open' : ''">

                        <!-- NOTIFICACIONES -->
                        <ng-template [hasRole]="['Alumno']">
                            <button mat-icon-button (click)="openNotifications($event);" [matBadge]="countTareas(notificacionesTareas)" [matBadgeHidden]="(taskListService.open$ | async) || !countTareas(notificacionesTareas)" matTooltipPosition="below" [matTooltip]="(taskListService.open$ | async) ? ('notificaciones.icon' | translate) : ('notificaciones.titulo' | translate)"
                                [attr.aria-label]="(taskListService.open$ | async) ? ('notificaciones.icon' | translate) : ('notificaciones.titulo' | translate)" class="default-button default-button--xs default-button--circle default-button__primary--transparent task-menu-btn default-badge mb-2">

                                <mat-icon *ngIf="!(taskListService.open$ | async)"
                                    class="default-text">content_paste</mat-icon>
                                <mat-icon *ngIf="(taskListService.open$ | async)"
                                    class="grey-text__light">chevron_right</mat-icon>
                            </button>
                        </ng-template>

                        <mat-icon *ngIf="(taskListService.open$ | async)" class="title-icon position-absolute grey-text__lightest" matTooltipPosition="below" [matTooltip]="'materia.mis-temas.tabla.acciones.recargar' | translate" [attr.aria-label]="'materia.mis-temas.tabla.acciones.recargar' | translate">

                            pending_actions
                        </mat-icon>

                        <div *ngIf="(taskListService.open$ | async)" class="h-100 d-flex flex-column justify-content-between">

                            <div class="overflow-auto">
                                <div *ngIf="!countTareas(notificacionesTareas)" class="w-100 text-center text__small mb-3 px-3">
                                    <span>{{'notificaciones.tareas.no-tareas' | translate}}</span>
                                </div>

                                <div *ngFor="let item of notificacionesTareas" class="w-100 text-center mb-3 container-text">
                                    <ng-container *ngIf="item.tareas.length > 0">

                                        <header class="grey-text" [id]="dateHelper.getOffsetLocal(item.fecha) | date: 'dd MMM y'">
                                            {{ dateHelper.getOffsetLocal(item.fecha) | date: 'dd MMM y' }}
                                        </header>

                                        <ul class="text-start mx-3 mt-2 list-unstyled">
                                            <li *ngFor="let tarea of item.tareas" [ngClass]="colorHelper.getColor(tarea.materia.color)">
                                                <div class="task-item d-flex mb-3" tabindex="0" (click)="routeTarea(tarea.id)" (keyup.enter)="routeTarea(tarea.id)">
                                                    <mat-icon class="custom-icon">assignment</mat-icon>
                                                    <div class="d-flex flex-column ms-2">
                                                        <span class="cursor-pointer link grey-text font-weight-bold">{{tarea.titulo}}</span>
                                                        <span class="text__light mt-1">{{tarea.materia.curso.ordinal}}
                                                            {{tarea.materia.curso.etapa.acronimo}},
                                                            {{tarea.materia.nombre}}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>

                                    </ng-container>
                                </div>

                                <div class="w-100 text-center mb-3 px-3">
                                    <a routerLink="/inicio/tareas" class="text__small grey-text" [ngClass]="router.url == '/inicio/tareas' ? 'disable-link' : ''">{{'notificaciones.tareas.ver-todas'
                                        | translate}}</a>
                                </div>
                            </div>

                            <ng-template [hasRole]="['Alumno']">
                                <div *ngIf="countTareas(notificacionesTareas)" class="tasklist-calendar__container">
                                    <mat-calendar [minDate]="minDate" [maxDate]="maxDate" (monthSelected)="getCalendarBadges()" (selectedChange)="navigateToTaskList($event)" class="tasklist-calendar" [(selected)]="selectedDate" role="application">
                                    </mat-calendar>
                                </div>
                            </ng-template>

                        </div>

                    </div>
                </ng-container>
            </ng-template>
        </div>

        <ng-template [hasRole]="['Profesor']">
            <!-- <aula-planeta-edition-add></aula-planeta-edition-add> -->
            <aula-planeta-edition-bar></aula-planeta-edition-bar>
        </ng-template>


        <aside *ngIf="!isEvaluacionMicrosoft() && (!(ltiManagementService.moodle$ | async) && !isBuscador()) && !smartlinkManagementService.smartlink && !temasManagementService.fullscreen" class="floating-buttons position-fixed flex-column" [ngClass]="{
        'd-flex': !smartlinkManagementService.smartlink,
        'open-tasklist': (taskListService.open$ | async),
        'z-below': (temasManagementService.fullscreen && !smartlinkManagementService.smartlink),
        'd-none': (temasManagementService.fullscreen && smartlinkManagementService.smartlink),
        'floating-buttons-lti': (ltiManagementService.moodle$ | async)
        }">

            <!-- <button mat-fab matTooltipPosition="left" [matTooltip]="isOpenContext ? ('flotantes.ayuda.cerrar' | translate) : ('flotantes.ayuda' | translate)" (click)="openContext($event)" [attr.aria-label]="isOpenContext ? ('flotantes.ayuda.cerrar' | translate) : ('flotantes.ayuda' | translate)"
                class="mat-elevation-z0" [ngClass]="{ 'help': !isOpenContext}">

                <mat-icon [ngClass]="isOpenContext ? 'floating-close' : ''">{{isOpenContext ? 'close' :
                    'question_mark'}}</mat-icon>
            </button>

            <div *ngIf="isOpenContext" aria-modal="true" aria-labelledby="helpTitle" class="position-absolute context-help mat-elevation-z3 open" role="dialog" (focusout)="captureFocus($event)">

                <div *ngIf="(ltiManagementService.moodle$ | async)" class="context-help__header p-3 d-flex flex-column">
                    <button type="button" class="btn btn-link btn-close" [attr.aria-label]="'flotantes.ayuda.cerrar' | translate" (click)="openContext($event)">
                        <mat-icon>close</mat-icon>
                    </button>

                    <ng-template [hasPermissions]="['BuscadorLtiAndalucia']">
                        <h1 id="helpTitle" class="h5 mb-0">
                            <small class="d-block mb-2">{{'flotantes.ayuda.andalucia.title' | translate}}</small> {{'flotantes.ayuda.andalucia.description' | translate}}
                        </h1>
                    </ng-template>

                    <ng-template [hasPermissions]="[
            'BuscadorLtiCreate',
            'BuscadorLtiCyL',
            'BuscadorLtiM5EDigitalizacion',
            'BuscadorLtiM5EUniversal'
            ]">
                        <h1 id="helpTitle" class="h5 mb-0">
                            <small class="d-block mb-2">{{'flotantes.ayuda.madrid.title' | translate}}</small> {{'flotantes.ayuda.madrid.description' | translate}}
                        </h1>
                    </ng-template>

                    <ng-template [hasPermissions]="['BuscadorLtiEdixgal']">
                        <h1 id="helpTitle" class="h5 mb-0">
                            <small class="d-block mb-2">{{'flotantes.ayuda.edixgal.title' | translate}}</small> {{'flotantes.ayuda.edixgal.description' | translate}}
                        </h1>
                    </ng-template>
                </div>

                <div *ngIf="!(ltiManagementService.moodle$ | async)" class="context-help__header p-3 d-flex flex-column">
                    <button type="button" class="btn btn-link btn-close" [attr.aria-label]="'flotantes.ayuda.cerrar' | translate" (click)="openContext($event)">
                        <mat-icon>close</mat-icon>
                    </button>

                    <h1 id="helpTitle" class="h5 mb-0">
                        <small class="d-block mb-2">{{'flotantes.ayuda.title' | translate}}</small> {{'flotantes.ayuda.subtitle' | translate}}
                    </h1>
                </div>

                <mat-list class="overflow-auto py-0 context-help__list">
                    <a *ngFor="let item of contextMenu;" [href]="item.url" target="_blank">
                        <mat-list-item>
                            <mat-icon class="default-text material-icons-outlined" matListIcon>{{item.icon}}</mat-icon>

                            <h2 class="subtitle default-text mb-2" matLine>{{item.subtitle}}</h2>
                            <h3 class="title font-weight-bold grey-text" matLine>{{item.title}}</h3>
                            <p class="description grey-text__lightest" matLine>{{item.description}}</p>
                        </mat-list-item>
                    </a>
                </mat-list>
            </div> -->


            <ng-template [hasRole]="['Profesor']">
                <aula-planeta-incidencias *ngIf="!(ltiManagementService.moodle$ | async) "></aula-planeta-incidencias>
            </ng-template>


            <button mat-fab [disabled]="!isScrolling" [ngClass]="!isScrolling ? 'disabled-button-upward' : 'help'" matTooltipPosition="left" [matTooltip]="'flotantes.arriba' | translate" [attr.aria-label]="'flotantes.arriba' | translate" class="mat-elevation-z0"
                (click)="scrollToTop($event)">

                <mat-icon>arrow_upward</mat-icon>
            </button>
        </aside>

    </div>

</div>
